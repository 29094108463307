import { defineStore } from "pinia";
import { Message } from 'view-ui-plus';
import api from "@/service/http";
import configs from "@/service/configs";
import utils from "@/libs/util";

//default defineStore
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: "",
      userlist: [],
      column: [],
      columnbyparentid: [],
      setinfo: "",
      configs: { ...configs },
      hub: ""
    };
  },
  getters: {},
  actions: {
    async login(data, callback) {
      await api.post("/user/login", data, callback).then(({ d, res }) => {
        this.user = d;
        return res;
      });
    },
    async rewriteuserinfo(data) {
      this.user = JSON.parse(JSON.stringify(data));
    },
    async getsetinfodetail(data, callback) {
      return await api.get("/setinfo/" + data, "", callback).then((d) => {
        this.setinfo = d;
      });
    },
    async wxlogin(data, callback) {
      return await api.post("/user/wxlogin", data, callback).then((d) => {
        this.user = d;
      });
    },
    async getcolumn(data, callback) {
      return await api.get("/column/findbyrole/" + data.flag, "", callback).then(({ d }) => {
        this.column = d || [];
      });
    },
    async getcolumnbyparentid(data, callback) {
      return await api.get("/column/" + data.flag + "?parentId=" + data.parentId, "", callback).then((d) => {
        this.columnbyparentid = d;
      });
    },
    async getchatunreadnum(callback) {
      return await api.get("/chat/unreadnum", "", callback).then((d) => {
        this.columnbyparentid = d;
      });
    },
    async getuser(data, callback) {
      return await api.get("/user", data, callback).then(d => {
        this.userlist = d;
        return d;
      })
    },
    async getuserbyusername(data, callback) {
      return await api.get("/user/byusername", data, callback).then((d) => {
        return d;
      });
    },
    async getuserdetail(data, callback) {
      return await api.get("/user/detail/"+data, "", callback).then(d => {
        return d;
      })
    },
    async getauditor(data, callback) {
      let url = "/user/auditor/" + data.type + "/" + data.userId + "/" + data.inorganize + "?designflow=1";
      if (data.flowBizId) {
        url += "&flowBizId=" + data.flowBizId;
      }
      if (data.bizShortName) {
        url += "&bizShortName=" + data.bizShortName;
      }
      if (data.nextPhaseId) {
        url += "&nextPhaseId=" + data.nextPhaseId;
      }
      return await api.get(url, "", callback).then((d) => {
        return d;
      });
    },
    async getuserloginlog(data, callback) {
      return await api.get("/user/simpleloginlog", data, callback).then((d) => {
        return d;
      });
    },
    async fileupload2(data, callback) {
      return await api.post("/v2/fileupload" + data.params, data.data, callback).then(({ res }) => {
        return res;
      })
    }
  },
});

export const useCommsStore = defineStore("comms", {
  state: () => {
    return {
      comms: {
        mychat: {
          state: false,
          type: ''
        },
        layoutcontent: {
          style: { "background-color": "#f3f3f3" }
        },
        userinfomodel:{
          state:false,
          id:""
        },
        phoneview:false,
        maintop: 0,
        menutype: false,
        loginout: { state: false, id: "" },
        refreshscheduling: { all: 0, alllocal: false, local: false },
        refreshnormalscheduling: { cache: false },
        completecustomerinfomodal: { state: false, id: "", datas: "", refresh: "", simple: false,multiget:false },
        consultsendnotice: { state: false, data: "" },
        updatemsgindialog: { data: "", refresh: "",action:"" },
        repeatorderstate: false,
        reonline: "ing",
        mycustomertablemodal: { refresh: "" },
        opendialog: { state: false, datas: "" },
        refreshchatunread: { refresh: 0, action: "" },
        refreshdialog: { state: false },
        schedulingtypemodal: { state: false, flag: 0 },
        updatemsgsendstatus: { id: "", status: 0 },
        sourcemodal: { state: false },
        spreadaccountmodal: { state: false, selected: [] },
        imgtagtreemodal: { state: false, id: 0 },
        unreadnum: 0,
        refreshreadnum:0,
        webmodelrefresh: {},
        kaoqinlist: []
      },
      webmodel: {
        state: false,
        name: "",
        title: "",
        path: "",
        query: "",
        synch: false, //判断是否将子iframe的数据传到前端 true代表传
        localdata: [],
        $route: "",
        refresh: "",
        subwebmodel: {
          state: false,
          name: "",
          title: "",
          path: "",
          query: "",
          localdata: [],
          refresh: "",
          onlyclosesub: false
        }
      },
      selected: "",
      useronline: { allnum: 0, onlinenum: 0, list: [] },
      webmodeldatas: ""
    };
  },
  getters: {},
  actions: {
    async setcomms(data) {
      for (let item in this.comms) {
        for (let key in data) {
          if (item == key) {
            let _obj = this.comms[item];
            if (typeof data[key] == 'object') {
              for (let key1 in data[key]) {
                _obj[key1] = data[key][key1];
              }
              this.comms[item] = JSON.parse(JSON.stringify(_obj));
            }
            else {
              this.comms[item] = data[key];
            }
          }
        }
      }
    },
    async refreshreadnum() {
      this.comms.refreshreadnum = Math.random();
    },
    async setuseronline(data) {
      for (let key in data) {
        this.useronline[key] = data[key];
      }
      this.useronline = JSON.parse(JSON.stringify(this.useronline));
    },
    async initwebmodel(data) {
      if (data && data["action"] == "synchwebmodel") {
        for (let key in data.datas) {
          this.webmodel[key] = data.datas[key];
        }
      }
      else {
        for (let key in data) {
          this.webmodel[key] = data[key];
        }
      }
      this.webmodel.synch = false;
      this.webmodel = JSON.parse(JSON.stringify(this.webmodel));
    },
    async setwebmodel(data) {
      this.selected = "";
      let $currentroute = utils.currentPath();
      if (data.datas) {
        this.webmodeldatas = data.datas;
      }

      if (this.webmodel.state == true && this.webmodel.path) {
        //表示已经有webmodel已打开
        //当前操作要打开subwebmodel
        for (let key in data) {
          if (key != "datas") {
            this.webmodel.subwebmodel[key] = data[key];
          }
        }
        this.webmodel.subwebmodel.onlyclosesub = false;
        this.webmodel.subwebmodel.$route = $currentroute;
        if (data.refresh) {
          this.webmodel.refresh = data.refresh;
          this.webmodel.subwebmodel.refresh = data.refresh;
        }
        else{
          this.webmodel.refresh = "";
          this.webmodel.subwebmodel.refresh = "";
        }
        if (data["setlocal"]) {
          this.webmodel.subwebmodel.localdata = JSON.parse(JSON.stringify(data["setlocal"]));
        }
        this.webmodel.synch = true;

        this.webmodel = JSON.parse(JSON.stringify(this.webmodel));
      }
      else {

        for (let key in data) {
          if (key != "datas") {
            this.webmodel[key] = data[key];
          }
        }
        this.webmodel.$route = $currentroute;
        this.webmodel.synch = true;
        if (data.refresh) {
          this.webmodel.refresh = data.refresh;
        }
        else{
          this.webmodel.refresh = "";
        }
        
        if (data["setlocal"]) {
          this.webmodel.localdata = JSON.parse(JSON.stringify(data["setlocal"]));
        }
      }


    },
    async webmodelsubmit(refresh) {
      Message.success("操作成功");
      if (this.webmodel.refresh) {
        this.webmodel[this.webmodel.refresh] = Math.random();
      }
      if(refresh){
        this.webmodel["refresh"] = Math.random();
      }
      setTimeout(() => {
        this.closewebmodel();
      }, 1000)
    },
    async closewebmodel(data) {
      if (data && data.closeandopen) {// && data["action"]
        console.info("关闭其它的webmodel");
        this.webmodel.closeandopen = data.closeandopen;
      }
      // else {
        if (this.webmodel.state && this.webmodel.subwebmodel.state) {
          this.webmodel.synch = true;
          this.webmodel.subwebmodel.state = false;
          this.webmodel.subwebmodel.title = "";
          this.webmodel.subwebmodel.path = "";
          this.webmodel.subwebmodel.query = "";
          this.webmodel.subwebmodel.onlyclosesub = true;
          this.webmodel.subwebmodel.$route = "";
          // this.webmodel.subwebmodel.refresh = "";
          this.webmodel = JSON.parse(JSON.stringify(this.webmodel));
        }
        else {
          this.webmodel.state = false;
          this.webmodel.synch = true;
          this.webmodel.title = "";
          this.webmodel.path = "";
          this.webmodel.query = "";
          this.webmodel.subwebmodel.onlyclosesub = false;
          this.webmodel.$route = "";
          // this.webmodel.refresh = "";
          this.webmodel = JSON.parse(JSON.stringify(this.webmodel));
        }
      // }
    },
    async onselected(data) {
      //通过webmodel中的path设置key
      //然后把数据拼接成{key:'',datas:''}格式
      let _state = this.webmodel.state;
      let _path = this.webmodel.path;
      let _route = this.webmodel.$route;
      let _substate = this.webmodel.subwebmodel.state;
      let _subpath = this.webmodel.subwebmodel.path;
      let _subroute = this.webmodel.$route;

      let path = "";
      let $route = "";
      if (_state && _substate) {
        path = _subpath;
        $route = _subroute;
      }
      else {
        path = _path;
        $route = _route;
      }
      path = path.split("?")[0].replace("/", "");




      let result = { key: path, datas: data, $route: $route };

      this.selected = JSON.parse(JSON.stringify(result));
      this.closewebmodel();
    },
    async synchselected(data) {
      if (data.action=="synchselected") {
        this.selected = data.datas;
      }
      else {
        this.selected = data;
      }
    },
    async initwebmodeldatas(data) {
      this.webmodeldatas = data;
    },
    // async onlocalpage(obj,$router){
    //   this.closewebmodel();
    //   setTimeout(()=>{
    //     console.info($router);
    //     console.info(obj);
    //   $router.push(obj);
    // },1000);
    // }
  }
});
