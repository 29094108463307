const debug = process.env.NODE_ENV != 'production';
let _base = "http://localhost:8081/";//http://dcweb.hndcjz.com/
let _basepath = "http://localhost:51405/";

let _base_chat = "http://localhost:51405/";//http://dcweb.hndcjz.com/
let _base1 = "http://localhost:51405/";//http://localhost:51405/
let filepath = "https://resource.hndcjz.com/static/img";
if(!debug){
    _base = "https://resource.hndcjz.com/";//http://121.40.242.194:8081/
    _base_chat = "https://external.hndcjz.com/"; //"https://dcweb.hndcjz.com/";
    _basepath = "https://external.hndcjz.com/";
}
export default {
    debug:false,
    sitepath:"https://www.hndcjz.com/",
    user:"",
    column:"",
    base:_base,//非接口要用的地址
    basepath:_basepath, //接口要用的地址
    filepath:filepath,
    uploadbase:_base,
    exportpath:_base+"api/backstage/FileView/ExportData/",
    viewpath:  _basepath+"api/backstage/fileview",
    viewpath2:  _basepath+"api/backstage/v2/fileview",
    downloadpath:  _base+"api/FileDownload",
    downloadpath2:  _base+"api/backstage/v2/filedownload",
    imgTxt:"/api/backstage/FileUpload/ImgTxt?dir=fileserverimg",
    contractpath:_base+"api/ContractDownload",
    upload:_base+"api/backstage/FileUpload?",
    upload2:_basepath+"api/backstage/v2/FileUpload?",
    upload1:_base+"api/backstage/networkdiskfileserver/upload?",
    kindeditorupload:"/api/backstage/FileUpload",
    kindeditorplugin:_base1+"/static/kindeditor/plugins/",
    kindeditoritem:[
        'source', '|', 'undo', 'redo', '|',  'cut', 'copy', 'paste',
        'plainpaste', 'wordpaste', '|', 'justifyleft', 'justifycenter', 'justifyright',
        'justifyfull', 'insertorderedlist', 'insertunorderedlist', 'indent', 'outdent', 'subscript',
        'superscript', 'clearhtml', 'quickformat', 'selectall', '|', '/',
        'formatblock', 'fontname', 'fontsize', '|', 'forecolor', 'hilitecolor', 'bold',
        'italic', 'underline', 'strikethrough', 'lineheight', 'removeformat', '|', 'image',
        'media', 'insertfile','|',  'table', 'hr',  'pagebreak',
        'anchor', 'link', 'unlink'
    ],
    flows:{
        remittance:"01ad7673-bbe5-11e8-b7d7-144f8a0608a0",
        projecttask:"0713bd31-bbe5-11e8-b7d7-144f8a0608a0",
        projectinsertprice:"a83b7f24-6eb1-11ea-a33c-0a0027000017",
        schedulingedit:"6407381a-b11b-11e9-9c49-0a0027000030",
        archivesevaluate:'6a3ba475-bb72-11e9-a865-0a0027000017',
        personneledit:'bad3d832-d115-11e8-9583-144f8a0608a0',
        personnel:"bad3d832-d115-11e8-9583-144f8a0608a0"
    },
    pdfjsurl: "https://resource.hndcjz.com/static",//_base,
    baseurl:_base_chat,
    basechaturl:_base_chat
}