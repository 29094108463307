<style lang="scss" scoped>

</style>
<template>
  <img ref="lazyimg" :src="src" :onload="imgLoadHandle" alt="加载中.." :onerror="imgErrorHandle" style="width:100%;"/> 
</template>

<script>
export default {
  name: 'ImageLazy',
  props: {
    src: {
      type: String,
      defualt: ""
    },
    type: {
      type: String,
      default: ""
    },
    key:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      success: false,
      loadimgsrc: "",
      img: ""
    }
  },
  mounted() {
  },
  methods: {
    loadImg(){
      let $lazyimg = this.$refs.lazyimg;
      if($lazyimg){
        if (this.type == "tx") {
          $lazyimg.setAttribute("src",this.static+"/default_tx1.png");
          this.$emit("update:src",this.static+"/default_tx1.png");
        }
        else if (this.type == "zl") {
          $lazyimg.setAttribute("src",this.static+"/zl.png");
          this.$emit("update:src",this.static+"/zl.png");
        }
        else if (this.type == "report") {
          $lazyimg.setAttribute("src",this.static+"/report.png");
        }
        else if (this.type == "leida") {
          $lazyimg.setAttribute("src",this.static+"/leida.png");
        }
        else if (this.type == "tz") {
          $lazyimg.setAttribute("src",this.static+"/tz.png");
        }
        else if(this.type == "noimg"){
          $lazyimg.setAttribute("src",this.static+"/noimg.png");
        }
        else if(this.type == "company"){
          $lazyimg.setAttribute("src",this.static+"/default_tx1.jpg");
          this.$emit("update:src",this.static+"/default_tx1.jpg");
        }
      }
    },
    imgLoadHandle(){
      
    },
    imgErrorHandle(){
      this.loadImg();
    }
  },
  watch:{
    
  }
}
</script>

