
<template>
    <div>
        <component :is="componentName" v-model="value" @on-change="submitHandle">
        </component>
        <teleport to=".filtertags" v-if="filterstate">
            <span>
                <Tag v-for="(d,i) in tags" :key="'teleporttags'+i" closable size="large" color="warning"
                style="margin:0px;margin-right: 5px;"
                @on-close="closeHandle(i)">
                {{d.label+(d.aliasvalue || d.value)}}
                </Tag>
                <Button type="error" icon="md-close" @click="clearHandle"></Button>
            </span>
        </teleport>
    </div>
</template>
<script>
import AfterProblemAdvanceFilter from "./afterproblem";
import AfterTaskAdvanceFilter from "./aftertask";
import ConsultAdvanceFilter from "./consult";
import OrderAdvanceFilter from "./order";
import ProjectAdvanceFilter from "./project";
import TeamAfterTaskAdvanceFilter from "./teamaftertask";
import TeamTaskAdvanceFilter from "./teamtask";
export default {
    name: "AdvanceFilter",
    data() {
        return {
            componentName: this.bind + "AdvanceFilter",
            filterstate: false,
            cstate: false,
            tags:[]
        }
    },
    components: { AfterProblemAdvanceFilter, AfterTaskAdvanceFilter, ConsultAdvanceFilter, OrderAdvanceFilter, ProjectAdvanceFilter, TeamAfterTaskAdvanceFilter, TeamTaskAdvanceFilter },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    props: {
        modelValue: Boolean,
        bind: String,
        clearData: Function
    },
    methods: {
        submitHandle(e) {
            this.tags = e;
            this.$emit("on-change",this.tags);
            // this.cstate = false;
            setTimeout(() => {
                this.filterstate = true;
                this.$emit("update:modelValue", false);
            }, 100);
        },
        closeHandle(index){
            this.tags.splice(index,1);
            this.$emit("on-change",this.tags);
            if(this.tags.length==0){
                this.filterstate = false;
            }
        },
        clearHandle(){
            this.tags=[];
            this.filterstate = false;
            this.$emit("on-change",this.tags);
        }
    }
}
</script>