import { defineStore } from 'pinia';
// import {useUserStore} from '@/store/user/user';
import api from '@/service/http';
// import utils from '@/libs/util'
// import configs from '@/service/configs';

export const usePersonnelStore = defineStore('personnel', {
    state: () => {
        return {
            // schedulinglist: []
        }
    },
    getters: {
        // getProductColumn(state) {
        //   return function (cache = true) {
        //     if (cache) {
        //       if (state.productcolumnlist.length > 0) {
        //         return new Promise((resolve) => {
        //           resolve(state.productcolumnlist)
        //         });
        //       }
        //     }
        //     return state.getproductcolumnall();
        //   }
        // },
    },
    actions: {
        async postpersonnel(data, callback) {
            return await api.post('/personnel/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
                return res;
            });
        },
        async addpersonnel(data, callback) {
            return await api.post('/personnel', data, callback).then(({ res }) => {
                return res;
            });
        },
        async resetpersonnel(data, callback) {
            return await api.post('/personnel/ResetApply/' + data, data, callback).then(({ res }) => {
                return res;
            });
        },
        async editpersonnel(data, callback) {
            return await api.put('/personnel', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getpersonneldetail(data, callback) {
            return await api.get('/personnel/detail/' + data, callback).then((d) => {
                return d;
            });
        },
        async delpersonnel(data, callback) {
            return await api.post('/personnel/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatepersonnelstate(data, callback) {
            return await api.put('/personnel/UpdateAuditor', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getownhours(callback) {
            return await api.get('/personnel/OwnHours', "", callback).then((d) => {
                return d;
            });
        },
        async getpersonnelboard(callback) {
            return await api.get('/personnel/board', "", callback).then((d) => {
                return d;
            });
        },
        async getpersonneldate(data, callback) {
            return await api.get('/personnel/date', data, callback).then(({ res }) => {
                return res;
            });
        },
        //离职
        async postpersonneldimission(data, callback) {
            return await api.post('/personneldimission/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
                return res;
            });
        },
        async addpersonneldimission(data, callback) {
            return await api.post('/personneldimission', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editpersonneldimission(data, callback) {
            return await api.put('/personneldimission', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getpersonneldimissiondetail(data, callback) {
            return await api.get('/personneldimission/detail/' + data, "", callback).then((d) => {
                return d;
            });
        },
        async delpersonneldimission(data, callback) {
            return await api.post('/personneldimission/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatepersonneldimissionstate(data, callback) {
            return await api.put('/personneldimission/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getpersonneldimissionapplydetail(data, callback) {
            return await api.get('/personneldimission/applieddetail/' + data, "", callback).then((d) => {
                return d;
            });
        },
        //档案
        async postarchives(data, callback) {
            return await api.post('/archives/' + data.page + "/" + data.row, data, callback,["page","row","params"]).then(({ res }) => {
                return res;
            });
        },
        async addarchives(data, callback) {
            return await api.post('/archives', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editarchives(data, callback) {
            return await api.put('/archives', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getarchivesdetail(data, callback) {
            return await api.get('/archives/detail/' + data, "", callback).then((d) => {
                return d;
            });
        },
        async delarchives(data, callback) {
            return await api.post('/archives/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatearchivesfstate(data, callback) {
            return await api.put('/archives/updatefstate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatearchivesstate(data, callback) {
            return await api.put('/archives/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatecontractendtime(data, callback) {
            return await api.post('/archives/setcontractendtime', {}, callback).then(({ res }) => {
                return res;
            });
        },
        async syncharchivesuser(callback) {
            return await api.get('/archives/synchuser', "", callback).then((d) => {
                return d;
            });
        },
        async archivesbirthdaystats(data,callback) {
            return await api.get('/archives/birthdaystats/'+data.page+'/'+data.row, data, callback,["page","row"]).then((d) => {
                return d;
            });
        },
        async archivesjoinstatus(data,callback) {
            return await api.get('/archives/joinstatus/'+data.page+'/'+data.row, data, callback,["page","row"]).then((d) => {
                return d;
            });
        },
        //合同
        async postcontract(data, callback) {
            return await api.post('/contract/' + data.page + "/" + data.row, data, callback).then(({ res }) => {
                return res;
            });
        },
        async addcontract(data, callback) {
            return await api.post('/contract', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editcontract(data, callback) {
            return await api.put('/contract', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getcontractdetail(data, callback) {
            return await api.get('/contract/' + data, "", callback).then((d) => {
                return d;
            });
        },
        async delcontract(data, callback) {
            return await api.post('/contract/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updateacontractstate(data, callback) {
            return await api.put('/contract/UpdateState', data, callback).then(({ res }) => {
                return res;
            });
        },
        //节假日管理
        async postfestival(data, callback) {
            return await api.post('/festival/' + data.page + "/" + data.row, data, callback).then(({ res }) => {
                return res;
            });
        },
        async addfestival(data, callback) {
            return await api.post('/festival', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editfestival(data, callback) {
            return await api.put('/festival', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getfestivaldetail(data, callback) {
            return await api.get('/festival/detail/' + data, "", callback).then((d) => {
                return d;
            });
        },
        async delfestival(data, callback) {
            return await api.post('/festival/delete/' + data.delScheduling, data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatefestivalstate(data, callback) {
            return await api.put('/festival/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async autocreatefestival(data, callback) {
            return await api.get('/festival/autocreate/' + data, "", callback).then((d) => {
                return d;
            });
        },
        async editautocreatefestival(data, callback) {
            return await api.post('/festival/editautocreate', data, callback).then(({ res }) => {
                return res;
            });
        },
        //来访
        async postcomevisit(data, callback) {
            return await api.post('/comevisit/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then(({ res }) => {
                return res;
            });
        },
        async addcomevisit(data, callback) {
            return await api.post('/comevisit', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editcomevisit(data, callback) {
            return await api.put('/comevisit', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getcomevisitdetail(data, callback) {
            return await api.get('/comevisit/detail/' + data, callback).then(({ res }) => {
                return res;
            });
        },
        async getcomevisitdetailbyphone(data, callback) {
            return await api.get('/comevisit/detailbyphone?phone=' + data + "&kind=0", '', callback).then((d) => {
                return d;
            });
        },
        async delcomevisit(data, callback) {
            return await api.post('/comevisit/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updatecomevisitstate(data, callback) {
            return await api.put('/comevisit/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        //打印请求
        async postprintapply(data, callback) {
            return await api.post('/printapply/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then(({ res }) => {
                return res;
            });
        },
        async addprintapply(data, callback) {
            return await api.post('/printapply', data, callback).then(({ res }) => {
                return res;
            });
        },
        async editprintapply(data, callback) {
            return await api.put('/printapply', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getprintapplydetail(data, callback) {
            return await api.get('/printapply/detail/' + data, '', callback).then((d) => {
                return d;
            });
        },
        async delprintapply(data, callback) {
            return await api.post('/printapply/delete', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updateprintapplystate(data, callback) {
            return await api.put('/printapply/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async applyonlinenoscheduling(callback) {
            return await api.post('/scheduling/applyonline', {params:[]}, callback).then(({ res }) => {
                return res;
            });
        },
        async getapplyonlineresult(callback) {
            return await api.get('/scheduling/applyonlineresult', "", callback).then((d) => {
                return d;
            });
        },
        async getapplyonlinedetail(data, callback) {
            return await api.get('/scheduling/applyonlinedetail/' + data.userId, data, callback, ["userId"]).then((d) => {
                return d;
            });
        },
        async postauditapplyonline(data, callback) {
            return await api.post('/scheduling/auditapplyonline/' + data.userId + '/' + data.state, {}, callback).then(({ res }) => {
                return res;
            });
        },
        async postroleposition(data, callback) {
            return await api.post('/roleposition/' + data.page + "/" + data.row, data, callback).then(({ res }) => {
                return res;
            });
        },
        async updaterleposition(data, callback) {
            return await api.put('/roleposition', data, callback).then(({ res }) => {
                return res;
            });
        },
        async updaterolepositionstate(data, callback) {
            return await api.put('/roleposition/updatestate', data, callback).then(({ res }) => {
                return res;
            });
        },
        async getrolepositionbyid(data, callback) {
            return await api.get('/roleposition/detail/' + data, "", callback).then((d) => {
                return d;
            });
        },
        //档案信息管理
        async getarchivesinfomanage(data, callback) {
            return await api.get('/archivesinfo',data,callback).then((d) => {
                return d;
            });
        },
        async getarchivesinfoattrmanage(data, callback) {
            return await api.get('/archivesinfo/attr/'+data,"",callback).then((d) => {
                return d;
            });
        },
        async addarchivesinfomanage(data, callback) {
            return await api.post('/archivesinfo',data, callback).then(({res}) => {
                return res;
            });
        },
        async editarchivesinfomanage(data, callback) {
            return await api.put('/ArchivesInfo',data, callback).then(({res}) => {
                return res;
            });
        },
        async getarchivesinfomanagedetail(data, callback) {
            return await api.get('/ArchivesInfo/'+data,"", callback).then((d) => {
                return d;
            });
        },
        async delarchivesinfomanage(data, callback) {
            return await api.post('/ArchivesInfo/Delete',data, callback).then(({res}) => {
                return res;
            });
        },
        async updatearchivesinfomanagestate(data, callback) {
            return await api.put('/ArchivesInfo/UpdateState',data, callback).then(({res}) => {
                return res;
            });
        },
        async postpersonnelaudit(data, callback) {
            return await api.post('/personnel/audit',data, callback).then(({res}) => {
                return res;
            });
        },
        async recallpersonnel(data, callback) {
            return await api.post('/personnel/recall',data, callback).then(({res}) => {
                return res;
            });
        },
        async getpersonnelapplieddetail(data, callback) {
            return await api.get('/personnel/applieddetail/'+data.id,"", callback).then((d) => {
                return d;
            });
        },
        async getassetsapplieddetail(data, callback) {
            return await api.get('/assetsapply/applieddetail/'+data.id+"/"+data.state,"", callback).then((d) => {
                return d;
            });
        },
        async getarchiveevaluate(data, callback) {
            return await api.get('/archives/evaluate/'+data.id,"",callback).then((d) => {
                return d;
            });
        },
        async postarchiveevaluate(data, callback) {
            return await api.post('/archives/evaluate',data,callback).then(({res}) => {
                return res;
            });
        },
        async getrepeatarchives(data, callback) {
            return await api.get('/archives/repeat',data,callback,["page","row","params"]).then((d) => {
                return d;
            });
        },
        async addarchivematerial(data, callback) {
            return await api.post('/archives/addmaterial',data,callback).then(({res}) => {
                return res;
            });
        },
        async getarchivematerial(data, callback) {
            return await api.get('/archives/material/'+data,"",callback).then((d) => {
                return d;
            });
        },
        async addarchivecontract(data, callback) {
            return await api.post('/archives/addcontract',data,callback).then(({res}) => {
                return res;
            });
        },
        async getarchivecontract(data, callback) {
            return await api.get('/archives/contract/'+data,"",callback).then((d) => {
                return d;
            });
        },
    }
})