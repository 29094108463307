
<template>
    <Modal v-model="value" class="bindcustomer" width="830" :styles="{ 'top': '10px' }" :mask-closable="false"
        :closable="false">
        <template #header>
            <h3>高级搜索</h3>
        </template>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="80" inline>
                <FormItem label="开始时间">
                    <DatePicker type="date" v-model="formItem.startTime" placeholder="请选择开始时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem label="结束时间">
                    <DatePicker type="date" v-model="formItem.endTime" placeholder="请选择结束时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem label="设计套餐">
                    <Select style="width:180px" clearable filterable v-model="formItem.templateId">
                        <Option v-for="item in templatelist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="客户姓名">
                    <Input v-model="formItem.customerName" style="width:180px"></Input>
                </FormItem>
                <FormItem label="客户电话">
                    <Input v-model="formItem.customerPhone" style="width:180px"></Input>
                </FormItem>
                <FormItem label="销售姓名">
                    <Input v-model="formItem.saleUserName" readonly style="width:180px">
                    <template #append>
                        <Button icon="ios-search"
                            @click="setwebmodel({ state: true, path: '/userlist', query: { companyId: user.companyId } })"></Button>
                    </template>
                    </Input>
                </FormItem>
                <FormItem label="项目进度">
                    <InputNumber v-model="formItem.startProcess" style="width:83px"></InputNumber>
                    &nbsp;-&nbsp;
                    <InputNumber v-model="formItem.endProcess" style="width:80px"></InputNumber>
                </FormItem>
                <FormItem label="项目面积">
                    <InputNumber v-model="formItem.startAcreage" style="width:82px"></InputNumber>
                    &nbsp;-&nbsp;
                    <InputNumber v-model="formItem.endAcreage" style="width:82px"></InputNumber>
                </FormItem>
                <FormItem label="是否变更">
                    <Select style="width:180px" clearable filterable v-model="formItem.changeNum">
                        <Option v-for="item in projectchangeoption" :value="item.id" :key="'projectchangeoption' + item.id">
                            {{ item.name }}</Option>
                    </Select>
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button icon="md-close" @click="$emit('update:modelValue', false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </template>
    </Modal>
</template>
<script>
import util from '@/libs/util.js';
import dict from '@/libs/dict.js';
import { useUserStore, useCommsStore } from "@/store/user/user";
import { useBaseInfoStore } from '@/store/crm/baseinfo';
import { storeToRefs } from "pinia";

export default {
    name: "ProjectAdvanceFilter",
    setup() {
        const userstore = useUserStore();
        const baseinfostore = useBaseInfoStore();
        const { user } = storeToRefs(userstore);
        const commsstore = useCommsStore();
        const { webmodel, selected } = storeToRefs(commsstore);


        return {
            user,
            baseinfostore,
            webmodel,
            selected,
            setwebmodel: commsstore.setwebmodel
        }
    },
    data() {
        return {
            provincelist: [],
            citylist: [],
            sourcelist: [],
            paywaylist: [],
            combolist: [],
            organizelist: [],
            formItem: {
                startTime: "",
                endTime: "",
                templateId: "",
                customerName: "",
                customerPhone: "",
                saleUserId: "",
                saleUserName: "",
                startProcess: 0,
                endProcess: 0,
                startAcreage: 0,
                endAcreage: 0,
                changeNum: -1
            },
            templatelist: [],
            filter: [],
            projectchangeoption: [{
                id: -1,
                name: "全部"
            }, {
                id: 0,
                name: "无变更"
            }, {
                id: 1,
                name: "有变更"
            }],
            taskstatelist: dict.taskstate()
        }
    },
    props: {
        modelValue: Array,
        clearData: Function
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    mounted() {
    },
    methods: {
        advanceSearchHandle() {
            this.advancestate = true;
            if (this.templatelist.length == 0) {
                this.baseinfostore.getprojecttemplate({ page: 1, row: 100, state: -1 }, (d, { code }) => {
                    this.templatelist = [];
                    if (code == 0) {
                        this.templatelist = d;
                    }
                });
            }
        },
        submitSearchHandle() {
            let _filter = [];
            for (let key in this.formItem) {
                if (this.formItem[key]) {
                    if (key == "customerName") {
                        _filter.push({
                            name: "customerName",
                            condition: 2,
                            label: "客户姓名",
                            value: this.formItem[key]
                        })
                    }
                    else if (key == "customerPhone") {
                        _filter.push({
                            name: "customerPhone",
                            condition: 0,
                            label: "客户电话",
                            value: this.formItem[key]
                        })
                    }
                    else if (key == "saleUserId") { // && this.formItem[key]!=this.user.id
                        _filter.push({
                            name: "saleUserId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "销售人员",
                            aliasvalue: this.formItem["saleUserName"]
                        })
                    }
                    else if (key == "templateId") {
                        _filter.push({
                            name: "templateId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "项目模板"
                        })
                    }
                }
            }
            //开始结束时间
            if (this.formItem.startTime && this.formItem.endTime) {
                let _startTime = util.formatTime(this.formItem.startTime);
                let _endTime = util.formatTime(this.formItem.endTime);
                _filter.push({
                    name: "AddTime",
                    condition: 8,
                    value: _startTime.split(" ")[0] + "," + _endTime.split(" ")[0]
                })
            }

            //开始结束进度
            if (this.formItem.startProcess > 0 || this.formItem.endProcess > 0) {
                _filter.push({
                    name: "remitProcess",
                    condition: 13,
                    value: this.formItem.startProcess + "@" + this.formItem.endProcess,
                    label: "项目进度"
                })
            }

            //开始结束面积
            if (this.formItem.startAcreage > 0 || this.formItem.endAcreage > 0) {
                _filter.push({
                    name: "sumAcreage",
                    condition: 13,
                    value: this.formItem.startAcreage + "@" + this.formItem.endAcreage,
                    label: "项目面积"
                })
            }

            if (this.formItem.changeNum > -1) {
                _filter.push({
                    name: "changeNum",
                    condition: this.formItem.changeNum == 0 ? 2 : 6,
                    value: 0,
                    label: this.formItem.changeNum == 0?"无变更":"有变更"
                })
            }

            this.filter = _filter;
            this.advancestate = false;
            this.$emit("on-change", _filter);
        },
        clearAdvanceHandle() {
            for (let key in this.formItem) {
                if (key == "startTime" || key == "endTime") {
                    this.formItem.startTime = "";
                    this.formItem.endTime = "";
                }
                if (typeof this.formItem[key] == "string") {
                    this.formItem[key] = "";
                }
                else if (typeof this.formItem[key] == "number") {
                    this.formItem[key] = 0;
                }
            }
        }
    },
    watch: {
        "value": function () {
            if (this.value) {
                this.advanceSearchHandle();
            }
        },
        "clearData": function () {
            this.clearAdvanceHandle();
        },
        "selected": function () {
            let _key = this.selected.key;
            let _datas = this.selected.datas;
            if (_key == "userlist" && _datas) {
                this.formItem.saleUserName = _datas.userName;
                this.formItem.saleUserId = _datas.id;
            }
        }
    }
}
</script>