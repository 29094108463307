<template>
    <div style="flex:1;height:100%;display:flex;flex-direction:column;">
        <div id="menu0" style="flex:1;">
            <Menu ref="leftmenu" theme="dark" width="auto" :active-name="activeName" :open-names="openName"
                @on-select="select">
                <!-- 列表 -->
                <template v-if="menushowtype">
                    <template v-for="(d, i) in column">
                        <MenuItem v-if="d.children.length == 0 && d.url != '#'" :name="d.url" :key="'lmindex0_' + i">
                        <span class="mtxt">{{ d.name }}</span>
                        </MenuItem>
                        <MenuItem v-for="(dd, j) in d.children && d.children.filter(f => f.url != '#')" :name="dd.url"
                            :key="'lsmindex0_' + i + '_' + j">
                        <template slot="title">
                            <Icon :type="dd.icon"></Icon>
                            <span class="mtxt">{{ dd.name }}</span>
                        </template>
                        <span class="mtxt">{{ dd.name }}</span>
                        </MenuItem>
                    </template>
                </template>
                <!-- 分组 -->
                <template v-if="!menushowtype">
                    <MenuItem v-for="(d, i) in column.filter(f => f.children.length == 0)" :name="d.url" :key="'mindex0_' + i">
                        <Icon type="md-home"></Icon>
                        <span class="mtxt">{{d.name}}</span>
                    </MenuItem>
                    <Submenu v-for="(d, i) in column.filter(f => f.children.length > 0)" :name="d.name" :key="'mindex_' + i">
                        <template #title>
                            <Icon :type="d.icon"></Icon>
                            <span class="mtxt">{{ d.name }}</span>
                            <div class="togglemenubox">
                                <div class="togglemenuli" v-for="(dd, j) in d.children" :key="'togglemenubox' + j"
                                    @click="$router.push((dd.url + (dd.params ? dd.params : '')))">{{ dd.name }}</div>
                            </div>
                        </template>
                        <MenuItem v-for="(dd, j) in d.children" :name="dd.url + (dd.params ? dd.params : '')"
                            :key="'sindex_' + j">
                        <span class="mtxt" :key="'sindex_' + j">{{ dd.name }}</span>
                        </MenuItem>
                    </Submenu>
                </template>
            </Menu>
        </div>
    </div>
</template>

<script>
import Scrollbar from 'smooth-scrollbar';
import { useUserStore } from "@/store/user/user";

// import { reactive, toRefs } from "vue";
import './style.scss'
import { storeToRefs } from 'pinia';
export default {
    name: 'Menus',
    props: ['value', 'txt'],
    setup() {
        const userstore = useUserStore();
        const { user, column } = storeToRefs(userstore);
        return {
            user,
            column,
            userstore
        }
    },
    data() {
        return {
            activeName: "/m",
            openName: ['0'],
            menushowtype: false,
            menutypestate: false,
            composestate: false,
            operation: {
                action: ""
            },
            privatesetting: ""
        }
    },
    mounted() {
        this.userstore.getcolumn({ flag: 1 }).then(d=>{
            this.$Spin.hide();
        });
        
        
        this.changeTheme();
    },
    methods: {
        changeTheme(color) {
            if (window.localStorage) {
                let _theme = window.localStorage.getItem("theme");
                if (color) {
                    document.body.classList.remove(_theme);
                    if (color == "default") {
                        window.localStorage.removeItem("theme");
                    }
                    else {
                        window.localStorage.setItem("theme", color);
                    }
                    document.body.classList.add(color);
                }
                else {
                    if (_theme) {
                        document.body.classList.add(_theme);
                    }
                }
            }
        },
        select(name) {
            if ((this.user.code == "SUPERADMIN" || this.user.code == "BOSS") && name == "m") {
                this.$router.push("/boss");
            }
            else {
                this.$router.push("/" + name);
            }
        },
        updateColumn() {
            //这个obj返回的是"我的工作台"
            let obj = this.findMenuId(this.column);
            // this.$set(this.openName,0,obj.id);

            //每个角色的核心功能都放在我的工作台下面,且在进入页面都要展开
            //如果第一个菜单不是"我的首页",就跳转
            let isdefaultpage = true;
            let defaultpageobj = { path: "", query: "" };
            if (this.column[0].name != "我的首页") {
                let children = this.column[0].children[0];
                if (children && children != null) {
                    let path = children.url;
                    let query = children.params;
                    isdefaultpage = false;
                    defaultpageobj = { path: path, query: query };
                }
            }


            if (this.column.length <= 5) {
                this.column.forEach(e => {
                    this.openName.push(e.name);
                });
                if (!isdefaultpage) {
                    this.$router.push({ path: defaultpageobj.path, query: defaultpageobj.query });
                }
            }
            else {
                if (isdefaultpage) {
                    this.openName.push(this.column[1].name);
                }
                else {
                    this.openName.push(this.column[0].name);
                    this.$router.push({ path: defaultpageobj.path, query: defaultpageobj.query });
                }
            }

            this.activeName = obj.url;
            this.$nextTick(() => {
                this.$refs.leftmenu.updateOpened();
                this.$refs.leftmenu.updateActiveName();
            });
        },
        findMenuId(d) {
            //此方法的目的是，让当前页的栏目高亮
            let path = this.$route.path;
            if (!d || d.length == 0) { return false; }
            let id = "";
            let url = "";
            for (let i = 0; i < d.length; i++) {
                let m = d[i];
                if (m.url != "#") {
                    if (("/" + m.url) == path) {
                        id = m.name;
                        url = m.url;
                        break;
                    }
                }
                for (let j = 0; j < m.children.length; j++) {
                    let s = m.children[j];

                    if (s) {
                        if (("/" + s.url) == path || ("/" + s.url) == path.substring(0, path.length - 4)) {
                            id = m.name;
                            url = s.url;
                            break;
                        }
                    }
                }
            }
            return { id: id, url: url };
        },
        setMenuType() {
            this.operation.action = "setmenutype";
            let _params = this.privatesetting;
            _params.menuType = this.menushowtype ? "list" : "group";
            this.editprivatesetting(_params);
            this.menutypestate = false;
        }
    },
    watch: {
        "column": function () {
            this.updateColumn();
            setTimeout(() => { this.$Spin.hide(); }, 500);
            this.$nextTick(() => {
                Scrollbar.init(document.querySelector('#menu0'), {});
            });
        },
        "privatesetting": function () {
            if (this.privatesetting.code == 0) {
                this.menushowtype = this.privatesetting.datas.menuType == "list" ? true : false;
            }
        }
    }
}
</script>

