
<template>
    <Modal v-model="value" width="830" :styles="{'top':'10px'}" :mask-closable="false" :closable="false">
        <template #header>
            <h3>高级搜索</h3>
        </template>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="80" inline>
                <FormItem label="开始时间">
                    <DatePicker type="date" v-model="formItem.startTime" placeholder="请选择开始时间" style="width: 180px"></DatePicker>
                </FormItem>
                <FormItem label="结束时间">
                    <DatePicker type="date" v-model="formItem.endTime" placeholder="请选择结束时间" style="width: 180px"></DatePicker>
                </FormItem>
                <FormItem label="客户状态">
                    <Select style="width:180px" v-model="formItem.state" label-in-value @on-change="stateChangeHandle">
                        <Option 
                        v-for="item in taskstatelist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="建房楼层">
                    <Input v-model="formItem.floor"  style="width:180px"></Input>
                </FormItem>
                <FormItem label="建房面积">
                    <Input v-model="formItem.acreage"  style="width:180px"></Input>
                </FormItem>
                <FormItem label="客资来源">
                    <Select style="width:180px" v-model="formItem.sourceId" label-in-value @on-change="sourceChangeHandle">
                        <Option 
                        v-for="item in sourcelist" 
                        :value="item.sourceId" 
                        :key="item.sourceId"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="销售团队" v-if="user.code=='BOSS' || user.code=='SUPERADMIN'">
                    <Select style="width:180px" v-model="formItem.organizeId" label-in-value @on-change="organizeChangeHandle">
                        <Option 
                        v-for="item in organizelist" 
                        :value="item.value" 
                        :key="item.value"
                        >{{item.label}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="销售人员">
                    <Input v-model="formItem.userName" readonly style="width:180px">
                        <template #append>
                            <Button icon="md-search" @click="setwebmodel({state:true,path:'/userlist',query:{companyId:user.companyId,organizeId:(user.code=='BOSS' || user.code=='SUPERADMIN')?'':user.organizeId}})"></Button>
                        </template>
                    </Input>
                </FormItem>
                <!-- <FormItem label="是否跟进">
                    <Select style="width:180px" v-model="formItem.hasRecord">
                        <Option value="-1">不限</Option>
                        <Option value="1">有跟进</Option>
                        <Option value="0">无跟进</Option>
                    </Select>
                </FormItem> -->
                <FormItem label="省份">
                    <Select style="width:180px" clearable filterable v-model="formItem.provinceId" label-in-value @on-change="provinceChangeHandle">
                        <Option 
                        v-for="item in provincelist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="市区">
                    <Select style="width:180px" clearable filterable v-model="formItem.cityId" label-in-value @on-change="cityChangeHandle">
                        <Option 
                        v-for="item in citylist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button icon="md-close" @click="$emit('update:modelValue',false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </template>
    </Modal>
</template>
<script>
    import util from '@/libs/util.js';
    import dict from '@/libs/dict.js';
    import { useUserStore,useCommsStore } from "@/store/user/user";
    import { useProductStore } from "@/store/product/product";
    import { useBaseInfoStore } from '@/store/crm/baseinfo';
    import { useSysInfoStore } from '@/store/user/sysinfo';
    import { useTaskStore } from '@/store/crm/task';
    import {storeToRefs} from "pinia";
    export default {
        name:"TeamTaskAdvanceFilter",
        setup() {
           const userstore = useUserStore();
           const productstore = useProductStore();
           const baseinfostore = useBaseInfoStore();
           const taskstore = useTaskStore();
           const sysinfostore = useSysInfoStore();
           const {user} = storeToRefs(userstore);
           const commsstore = useCommsStore();
           const {webmodel,selected} = storeToRefs(commsstore);
           
           
           return {
             user,
             productstore,
             baseinfostore,
             sysinfostore,
             taskstore,
             webmodel,
             selected,
             setwebmodel:commsstore.setwebmodel
           }
        },
        
        data() {
            return {
                provincelist:[],
                citylist:[],
                sourcelist:[],
                paywaylist:[],
                combolist:[],
                organizelist:[],
                formItem:{
                    startTime:"",
                    endTime:"",
                    state:-1,
                    owerId:"",
                    userName:"",
                    province:"",
                    provinceId:0,
                    city:"",
                    cityId:0,
                    acreage:"",
                    hasRecord:"-1",
                    organizeId:0,
                    statetxt:"",
                    source:"",
                    sourceId:"",
                    organize:""
                },
                taskstatelist:dict.taskstate()
            }
        },
        props:{
            modelValue:Array,
            cleraData:Function
        },
        computed: {
            value:{
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
        mounted() {
        },
        methods: {
            advanceSearchHandle(){
                this.advancestate = true;
                //加载身份
                if(this.provincelist.length==0){
                    this.baseinfostore.getarealinkage({provinceId:0,cityId:0},(d,{code})=>{
                        if(code==0){
                            this.provincelist = d.province;
                        }
                    });
                }
                //商品套餐
                if(this.combolist.length==0){
                    this.productstore.getproductcombo(0,(d,{code})=>{
                        if(code==0){
                            this.combolist = d;
                        }
                    });
                }
                //客户来源
                if(this.sourcelist.length==0){
                    this.baseinfostore.getsource({page:1,row:50,code:((this.user.code=="53SERVICES" || this.user.code=="SALES" || this.user.code=="SHOPSALES" || this.user.code=="ONLINEMARKET")?this.user.code:"OTHER")},(d,{code})=>{
                        if(code==0){
                            this.sourcelist = d;
                        }
                    });
                }
                //支付方式
                if(this.paywaylist.length==0){
                    this.baseinfostore.getpayway({page:1,row:50,state:0,flag:0}).then(d=>{
                        if(d.code==0){
                            this.paywaylist = d.datas;
                        }
                    });
                }

                if(this.organizelist.length==0){
                    this.sysinfostore.getorganize({id:5,state:0},(d,{code})=>{
                        if(code==0){
                            d.forEach(d1=>{
                                this.organizelist.push({
                                    label:d1.title,
                                    value:d1.id
                                });
                            })
                        }
                    });
                }
            },
            submitSearchHandle(){
                let _filter = [];
                for(let key in this.formItem){
                    if(this.formItem[key]){
                        if(key=="owerId"){ // && this.formItem[key]!=this.user.id
                            _filter.push({
                                name:"owerId",
                                condition:2,
                                value:this.formItem[key],
                                label:"销售人员:",
                                aliasvalue:this.formItem.userName
                            })
                        }
                        else if(key=="provinceId"){
                            _filter.push({
                                name:"provinceId",
                                condition:2,
                                value:this.formItem[key],
                                label:"省份:",
                                aliasvalue:this.formItem.province
                            })
                        }
                        else if(key=="cityId"){
                            _filter.push({
                                name:"cityId",
                                condition:2,
                                value:this.formItem[key],
                                label:"城市:",
                                aliasvalue:this.formItem.city
                            })
                        }
                        else if(key=="state" && this.formItem[key]!="-1"){
                            _filter.push({
                                name:"state",
                                condition:2,
                                value:this.formItem[key],
                                label:"状态:",
                                aliasvalue:this.formItem.statetxt
                            })
                        }
                        else if(key=="floor"){
                            _filter.push({
                                name:"floor",
                                condition:2,
                                value:this.formItem[key],
                                label:"楼层:"
                            })
                        }
                        else if(key=="organizeId" && this.formItem[key]>0){
                            _filter.push({
                                name:"organizeId",
                                condition:2,
                                value:this.formItem[key],
                                label:"销售团队:",
                                aliasvalue:this.formItem.organize
                            })
                        }
                        else if(key=="sourceId"){
                            _filter.push({
                                name:"sourceId",
                                condition:2,
                                value:this.formItem[key],
                                label:"销售来源:",
                                aliasvalue:this.formItem.source
                            })
                        }
                        // else if(key=="hasRecord" && this.formItem[key]!="-1"){
                        //     _filter.push({
                        //         name:"visitrecord.Remark ",
                        //         condition:this.formItem[key]==1?5:4
                        //     })
                        // }
                    }
                }

                if(this.formItem.acreage){
                    let _acreage = this.formItem.acreage.split("-");
                    if(_acreage.length==1){
                        _filter.push({
                            name:"Acreage",
                            condition:6,
                            value:_acreage[0],
                            label:"建房面积:"
                        })
                    }
                    else if(_acreage.length==2){
                        _filter.push({
                            name:"Acreage",
                            condition:8,
                            value:_acreage[0]+","+_acreage[1],
                            label:"建房面积:"
                        })
                    }
                }


                //开始结束时间
                if(this.formItem.startTime && this.formItem.endTime){
                    let _startTime = util.formatTime(this.formItem.startTime);
                    let _endTime = util.formatTime(this.formItem.endTime);
                    _filter.push({
                        name:"AddTime",
                        condition:8,
                        value:_startTime.split(" ")[0]+","+_endTime.split(" ")[0],
                        label:"开始结束日期:"
                    })
                }
                else if(this.formItem.startTime && !this.formItem.endTime){
                    let _startTime = util.formatTime(this.formItem.startTime);
                    _filter.push({
                        name:"AddTime",
                        condition:6,
                        value:_startTime.split(" ")[0],
                        label:"开始日期:"
                    })
                }
                else if(!this.formItem.startTime && this.formItem.endTime){
                    let _endTime = util.formatTime(this.formItem.endTime);
                    _filter.push({
                        name:"AddTime",
                        condition:7,
                        value:_endTime.split(" ")[0],
                        label:"结束日期:"
                    })
                }
                this.$emit("on-change",_filter);
            },
            provinceChangeHandle(val){
                this.formItem.province = val.label;
                this.formItem.provinceId = val.value;
                this.baseinfostore.getarealinkage({provinceId:val.value,cityId:0},(d,{code})=>{
                    if(code==0){
                        this.citylist = d.city;
                    }
                });
            },
            clearAdvanceHandle(){
                for(let key in this.formItem){
                    if(typeof this.formItem[key] =="string"){
                        this.formItem[key]="";
                    }
                    else if(typeof this.formItem[key]=="number"){
                        this.formItem[key]=0;
                    }
                }
            },
            search(){
                this.taskstore.gettaskbycontactinself(this.value4).then(d=>{
                    this.data2=[];
                    if(d.code==0){
                        this.data2 = d.datas;
                    }
                });
            },
            statsHandle(){
                this.$router.push({path:"/salereport",query:{id:"16"}});
            },
            cityChangeHandle(val){
                this.formItem.city = val.label;
                this.formItem.cityId = val.value;
            },
            organizeChangeHandle(val){
                this.formItem.organize = val.label;
                this.formItem.organizeId = val.value;
            },
            sourceChangeHandle(val){
                this.formItem.source = val.label;
                this.formItem.sourceId = val.value;
            },
            stateChangeHandle(val){
                this.formItem.state = val.value;
                this.formItem.statetxt = val.label;
            }
        },
        watch: {
            "value":function(){
                if(this.value){
                    this.advanceSearchHandle();
                }
            },
            "selected":function(){
                if(this.selected.key=="userlist" && this.selected.$route.path=="/teamtask"){
                    let _datas = this.selected.datas;
                    this.formItem.owerId = _datas.id;
                    this.formItem.userName = _datas.userName;
                }
                // if(!this.comms.webmodel.state && this.comms.webmodel.key=="refresh"){
                //     this.$set(this.ttcallback, "refresh", true);
                // }
                // else if(!this.comms.webmodel.state && this.comms.webmodel.key=="userlist" && this.comms.webmodel.action=="result"){
                //     this.formItem.owerId = this.comms.webmodel.result.id;
                //     this.formItem.userName = this.comms.webmodel.result.userName;
                // }
            },
            "clearData":function(){
                this.clearAdvanceHandle();
            }
        }
    }
</script>