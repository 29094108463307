import { defineStore } from 'pinia';
import { useUserStore } from '@/store/user/user';
import api from '@/service/http';
// import utils from '@/libs/util';
// import configs from '@/service/configs';

export const useCustomerStore = defineStore('customer', {
  state: () => {
    return {
      customertag: [],
      seacustomerlist: []
    }
  },
  getters: {
    // getCustomerTag:(state,actions){
    //   console.info(actions);
    //   return (cache=true){
    //     if(cache){
    //       if(state.customertag.length>0){
    //         return state.customertag;
    //       }
    //     }
    //     return this.getcustomertag1();
    //   }
    // },
    getCustomerTag(state) {
      return function (cache = true) {
        if (cache) {
          if (state.customertag.length > 0) {
            return new Promise((resolve) => {
              resolve(state.customertag)
            });
          }
        }
        return state.getcustomertag();
      }
    },
    getCustomerList(state) {
      return (cache) => {
        if (cache) {
          if (state.seacustomerlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.seacustomerlist)
            });
          }
        }
        return this.gettodayseacustomer();
      }
    }
  },
  actions: {
    //获取买家
    async postcustomer(data, callback) {
      return await api.post('/customer/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
        return res;
      });
    },
    async getprivatecustomer(data, callback) {
      return await api.get('/customer/private', data, callback).then((response) => {
        return response.data;
      });
    },
    async postsubscribecustomer(data, callback) {
      return await api.post('/customer/subscribe/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
        return res;
      });
    },
    async postdealcustomer(data, callback) {
      return await api.post('/customer/' + data.page + "/" + data.row + "?state=9", data, callback, ["state"]).then(({ res }) => {
        return res;
      });
    },
    async addcustomer(data, callback) {
      return await api.post('/customer', data, callback).then(({ res }) => {
        return res;
      });
    },
    async readdcustomer(data, callback) {
      return await api.post('/customer/readd', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addonlineservicecustomer(data, callback) {
      return await api.post('/customer/OnlineService', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editcustomer(data, callback) {
      return await api.put('/customer', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getcustomerdetail(data, callback) {
      return await api.get('/customer/' + data.id, data, callback,["id"]).then(({res}) => {
        return res;
      });
    },
    async getcustomerwholedetail(data, callback) {
      return await api.get('/customer/whole/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delcustomer(data, callback) {
      return await api.post('/customer/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatecustomerstate(data, callback) {
      return await api.put('/customer/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatecustomerkind(data, callback) {
      return await api.put('/customer/UpdateKind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getcustomerdetailbytaskid(data, callback) {
      return await api.get('/customer/bytaskid/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getcustomerislock(data, callback) {
      let url = '/customer/islock/' + data.id + "/" + data.confirm;
      if (data.limit) {
        url += "?limit=" + data.limit
      }
      return await api.get(url, "", callback).then((d) => {
        return d;
      });
    },
    async setcustomervalid(data, callback) {
      return await api.post('/customer/valid', data, callback).then(({ res }) => {
        return res;
      });
    },
    async setcustomerconfirm(data, callback) {
      return await api.post('/customer/confirm', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getseacustomerbytime(data, callback) {
      return await api.post('/customer/seabytime', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getcustomertag(data,callback) {
      let user = useUserStore().user;
      let showsea = user.code == "BOSS" || user.code == "SUPERADMIN" ? true : false;
      return await api.get("/customer/tag/-1/1?showsea=" + showsea, data, callback).then(({d}) => {
        this.customertag = d;
        return d;
      });
    },
    async updatecustomertag(data,callback){
        return await api.put('/customer/tag',data,callback).then(({res}) => {
            return res;
        });
    },
    async addcustomertag(data,callback){
        return await api.post('/customer/tag',data,callback).then(({res}) => {
            return res;
        });
    },
    async delcustomertag(data,callback){
        return await api.post('/customer/tag/delete/'+data.id,"",callback).then(({res}) => {
            return res;
        });
    },
    async addcustomertagrelated(data,callback){
        return await api.post('/customer/tagrelated',data,callback).then(({res}) => {
            return res;
        });
    },
    async delcustomertagrelated(data,callback){
        return await api.post('/customer/tagrelated/delete',data,callback).then(({res}) => {
            return res;
        });
    },
    async findcustomer(data, callback) {
      return await api.get('/customer/find', data, callback).then(({res}) => {
        return res;
      });
    },
    async gettodayseacustomer(data,callback) {
      return await api.get('/customer/todaysea', data,callback).then((d) => {
        this.seacustomerlist = d;
        return d;
      });
    },
    async setseacustomer(data, callback) {
      return await api.post('/customer/setsea', data, callback).then(({ res }) => {
        return res;
      });
    },
    async gethomesearch(data, callback) {
      return await api.get('/customer/homesearch', data, callback).then((d) => {
        return d;
      });
    },
    async updatecustomeroffer(data,callback){
        return await api.put('/customer/updateoffer',data,callback).then(({res}) => {
            return res;
        });
    },
    async updatecustomerarea(data,callback){
        return await api.put('/customer/updatearea',data,callback).then(({res}) => {
            return res;
        });
    },
    async getsubscribetosales(data,callback){
        return await api.get('/customer/subscribetosales/'+data.page+"/"+data.row,data,callback,["page","row"]).then((d) => {
            return d;
        });
    },
    async changecustomerkind(data,callback){
        return await api.post('/customer/changekind',data,callback).then(({res}) => {
            return res;
        });
    },
    async completecustomerInfo(data,callback){
        return await api.post('/customer/completeinfo',data,callback).then(({res}) => {
            return res;
        });
    },
    async getimportantcustomer(data,callback){
        return await api.get('/customer/important/'+data.id+"/"+data.page+"/"+data.row,data,callback,["page","row","params","id"]).then((d) => {
            return d;
        });
    },
    async repeatcustomernotice(data,callback){
        return await api.get('/consult/repeatcustomernotice/'+data,"",callback).then((d) => {
            return d;
        });
    },
    async addseacustomersetting(data,callback){
        return await api.post('/seacustomersetting',data,callback).then(({res}) => {
            return res;
        });
    },
    async getseacustomersetting(data,callback){
        return await api.get('/seacustomersetting',data,callback).then((d) => {
            return d;
        });
    },
    async getseacustomersettingdetail(data,callback){
        return await api.get('/seacustomersetting/detail/'+data.id+'/'+data.type,data,callback,["id","type"]).then(({res}) => {
            return res;
        });
    },
    async postseacustomersettingaudit(data,callback){
        return await api.post('/seacustomersetting/detailaudit',data,callback).then(({res}) => {
            return res;
        });
    }
  }
})