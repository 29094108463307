import axios from 'axios';
import { Message } from 'view-ui-plus';
import configs from '@/service/configs'
import utils from '@/libs/util'
//http request 拦截器
axios.interceptors.request.use(config => {
    // 这里的config包含每次请求的内容
    // 判断localStorage中是否存在api_token
    //   if (localStorage.getItem('api_token')) {
    //       //  存在将api_token写入 request header
    //       config.headers.apiToken = `${localStorage.getItem('api_token')}`;
    //   }
    //   return config;
    return config;
}, err => {
    return Promise.reject(err);
});


//http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            if (response.data.code == 996) {
                window.location.href = "/#/login"
            }
        }
        return response;
    }, err => {
        if (err.toString().indexOf("code 500") > -1) {
            window.location.href = "/#/pageerror";
        }
        else {
            Message.error("请求超时,请稍候再试~");
        }
        return Promise.reject(err);
    });

function checkCode(res, callback) {
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    let _code = res.data.code;
    if (_code === 99) {
        Message.config({ duration: 3 });
        Message.error(res.data.msg);
        return { d: res.data.datas, res: res.data };
    }
    else if (_code == 999) {
        Message.config({ duration: 3 });
        Message.error("系统异常,操作失败!");
        return res;
    }
    else {
        if (_code == 0 || _code == 1) {
            let _datas = res.data.datas;
            let _total = res.data.total;
            if (_code == 0) {
                setTimeout(() => {
                    if (callback) {
                        callback(_datas, { d:res.data,total: (_total ? _total : 0), code: _code, extradatas: res.data["extradatas"] });
                    }
                }, 50);
                return { d: res.data.datas, res: res.data };
            }
            else {
                setTimeout(() => {
                    if (callback) {
                        callback([], {d:res.data, total: 0, code: _code, extradatas: res.data["extradatas"] });
                    }
                }, 50);
                return { d: res.data.datas, res: res.data };
            }
        }
        else {
            setTimeout(() => {
                if (callback) {
                    callback("", { d:res.data,total: 0, code: _code, extradatas: "",msg:res.data.msg })
                }
            }, 50);
            return { d: res.data.datas, res: res.data };
        }
    }
}
export default {
    post(url, data, callback,extend) {
        let _data = data;
        let _url = url;
        if(data.params){
            _url = utils.concatUrl(url, data, extend);
            _data = data.params;
        }
        return axios({
            method: 'post',
            url: configs.basepath + 'api/backstage' + _url,
            data: _data,
            withCredentials: true
        }).then(
            (response) => {
                return checkCode(response, callback)
            }
        ).then(
            (res) => {
                return res;
            }
        )
    },
    get(url, data, callback, extend) {
        url = utils.concatUrl(url, data, extend);
        return axios({
            method: 'get',
            url: configs.basepath + 'api/backstage' + url,
            withCredentials: true,
            data
        }).then(
            (response) => {
                return checkCode(response, callback)
            }
        ).then(
            (res) => {
                return res;
            }
        )
    },
    delete(url, data, callback) {
        return axios({
            method: 'delete',
            url:configs.basepath + 'api/backstage' + url,
            data,
            withCredentials: true
        }).then(
            (response) => {
                checkCode(response, callback)
            }
        ).then(
            (res) => {
                return res;
            }
        )
    },
    put(url, data, callback) {
        return axios({
            method: 'put',
            url:configs.basepath + 'api/backstage' + url,
            data,
            withCredentials: true
        }).then(
            (response) => {
                return checkCode(response, callback)
            }
        ).then(
            (res) => {
                return res;
            }
        )
    },
    upload(url, data, progress) {
        return axios({
            url:configs.basepath + 'api/backstage' + url,
            data: data,
            method: "post",
            responseType: "json",
            onUploadProgress: progress
        });
    }
};
