import { useUserStore, useCommsStore } from "@/store/user/user";//useUserStore,

export const webmodelmixins = {
  data() {
    return {
      usertemp: "",
      submitloading: false
    }
  },
  created() {

  },
  mounted() {
    let _meta = this.$route.meta;
    var _height = _meta.height ? (document.documentElement.offsetHeight > _meta.height ? document.documentElement.offsetHeight : _meta.height) : document.documentElement.offsetHeight;
    var _action = "init";
    if (this.$route.query.title) {
      _meta.title = this.$route.query.title;
    }
    window.parent.postInitMessage({ action: _action, title: _meta.title, width: _meta.width, height: _height, header: _meta.header });
    window.postPageMessage = (e, u,d) => {
     
      if (e.action == "initstore") {
        if (u) {
          const userstore = useUserStore();
          userstore.rewriteuserinfo(u);
        }
        const commsstore = useCommsStore();
        if (d) {
          commsstore.initwebmodeldatas(d);
        }
        commsstore.initwebmodel(e.datas);
        if (e.selected) {
          commsstore.synchselected(e.selected);//.datas
        }
      }
    }
  },
  methods: {
    onlocalpage(path) {
      window.parent.postLocalPage(path);
    },
    delaysubmitloadingfalse() {
      setTimeout(() => {
        this.submitloading = false;
      }, 600);
    }
  },
  watch: {
    "webmodel": {
      deep: true,
      handler() {
        if (this.webmodel.synch) {
          window.parent.postInitMessage({
            action: "synchwebmodel",
            datas: this.webmodel
          });
        }
      }
    },
    "selected": {
      deep: true,
      handler() {
        window.parent.postResult({
          action: "synchselected",
          datas: this.selected
        });
      }
    }
  },
}