<style scoped lang="scss">
    .splitscreen{
        width:20%;
        background-color: red;
    }
</style>
<template>
    <component :is="comm">
        <template #content>
            <slot name="content"></slot>
        </template>
        <template #page>
            <slot name="page"></slot>
        </template>
    </component>
</template>

<script>
import Container from './container';
import WebModel from './webmodel';
export default {
    name: "DynamicContainer",
    components:{Container,WebModel},
    data() {
        return {
            comm:"Container"
        };
    },
    props:{
        type:{
            type:String,
            default:""
        }
    },
    mounted() {
        let path = this.$route.path;
        console.info(path);
        if(path.indexOf("webmodel")>-1 || this.type=="webmodel"){
            this.comm = "WebModel";
        }
        else{
            this.comm = "Container";
        }
    },
    methods: {
       
    }
}
</script>
