import { defineStore } from 'pinia';
// import {useUserStore} from '@/store/user/user';
import api from '@/service/http';
import utils from '@/libs/util'
// import configs from '@/service/configs';

export const useProductStore = defineStore('product', {
  state: () => {
    return {
      productlist: [],
      peripherallist: [],
      productcolumnlist: [],
      peripheralcolumnlist: [],
      proudctcombolist: [],
      proudctpromotionlist: []
    }
  },
  getters: {
    getProductColumn(state) {
      return function (cache = true) {
        if (cache) {
          if (state.productcolumnlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.productcolumnlist)
            });
          }
        }
        return state.getproductcolumnall();
      }
    },
    getPeripheralColumn(state) {
      return function (cache = true) {
        if (cache) {
          if (state.peripheralcolumnlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.peripheralcolumnlist)
            });
          }
        }
        return state.getperipheralproductcolumnall();
      }
    },
    getProductCombo(state) {
      return function (cache = true) {
        if (cache) {
          if (state.productcombolist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.productcombolist)
            });
          }
        }
        return state.getproductcombo();
      }
    },
    getProductPromotion(state) {
      return function (cache = true) {
        if (cache) {
          if (state.proudctpromotionlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.proudctpromotionlist)
            });
          }
        }
        return state.getproductpromotion();
      }
    }
  },
  actions: {
    async postproduct(data, callback) {
      return await api.post("/product/all/" + data.page + "/" + data.row, data, callback, ["page", "row"]).then(d => {
        this.productlist = d;
        return d;
      });
    },
    async addproduct(data, callback) {
      return await api.post('/product', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editproduct(data, callback) {
      return await api.put('/product', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getproductdetail(data, callback) {
      return await api.get('/product/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getproductdetails(data, callback) {
      return await api.get('/product/' + data.id + '/' + (data.preview || 0), data, callback, ["id", "preview"]).then((d) => {
        return d;
      });
    },
    async getproductdetailbycode(data, callback) {
      return await api.get('/product/bycode/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delproduct(data, callback) {
      return await api.post('/product/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateproductstate(data, callback) {
      return await api.put('/product/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async refreshproductcontentcache(data, callback) {
      return await api.get('/product/refreshcontentcache?companyId=' + data, "", callback).then(({ res }) => {
        return res;
      });
    },
    async findwaterimage(data, callback) {
      return await api.get('/product/findwaterimage', data, callback).then(({ res }) => {
        return res;
      });
    },
    async resetwaterimage(data, callback) {
      return await api.get('/v2/fileview/reset/' + data.id, data, callback, ["id"]).then(({ res }) => {
        return res;
      });
    },
    async resetallwaterimage(data, callback) {
      return await api.get('/v2/fileview/resetall/' + data.type, data, callback, ["type"]).then(({ res }) => {
        return res;
      });
    },
    async updateproductprice(data, callback) {
      return await api.put('/product/updateprice', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatepromotioninproductstate(data, callback) {
      return await api.put('/product/updatepromotionstate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addpromotioninproduct(data, callback) {
      return await api.post('/product/addpromotion/' + data.promotion, data, callback).then(({ res }) => {
        return res;
      });
    },
    async delpromotioninproduct(data, callback) {
      return await api.post('/product/delpromotion', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatepromotiontimeinproduct(data, callback) {
      return await api.post('/product/promotiontime', data, callback).then(({ res }) => {
        return res;
      });
    },
    //周边产品
    async getperipheralproduct(data, callback) {
      return await api.post('/peripheralproduct/all/' + data.page + "/" + data.row, data, callback,["page","row"]).then(({ d }) => {
        this.peripherallist = d;
        return d;
      });
    },
    async addperipheralproduct(data, callback) {
      return await api.post('/peripheralproduct', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editperipheralproduct(data, callback) {
      return await api.put('/peripheralproduct', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getperipheralproductdetail(data, callback) {
      return await api.get('/peripheralproduct/' + data + '/0', "", callback).then((d) => {
        return d;
      });
    },
    async delperipheralproduct(data, callback) {
      return await api.post('/peripheralproduct/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateperipheralproductstate(data, callback) {
      return await api.put('/peripheralproduct/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getproductcolumnall(data,callback) {
      return await api.get("/productcolumn/all", data, callback).then(({ d }) => {
        this.productcolumnlist = d;
        return d;
      });
    },
    async getperipheralproductcolumnall(data, callback) {
      return await api.get("/peripheralproductcolumn/all", data, callback).then(({ d }) => {
        this.peripheralcolumnlist = d;
        return d;
      });
    },
    async getproductcombobyprodcode(data, callback) {
      return await api.get("/productcombo/byproductcode/" + data, "", callback).then((d) => {
        return d;
      });
    },
    //elasticsearch
    async getesproduct(data, callback) {
      return await api.get('/product/getesproduct', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addallesproduct(data, callback) {
      return await api.get('/product/addallesproduct', data, callback, ["list"]).then((d) => {
        return d;
      });
    },
    async batchcreateproductwecode(data, callback) {
      return await api.get('/weservice/batchcreateproductwecode', data, callback).then((d) => {
        return d;
      });
    },
    async batchgeneralcasewecode(data, callback) {
      return await api.get('/weservice/batchgeneralcasewecode', data, callback).then((d) => {
        return d;
      });
    },
    // async editrelatedprice(data, callback) {
    //   return await api.get('/product/synchshoprelated', data, callback).then(({res}) => {
    //     return res;
    //   });
    // },
    async getshopproductdifference(data, callback) {
      return await api.get('/product/shopdifference/' + data.page + '/' + data.row + '/' + data.shopId, data, callback,["page","row","shopId"]).then((d) => {
        return d;
      });
    },
    async addshopproductdifference(data, callback) {
      return await api.post('/product/addshopdifference', data, callback).then(({ res }) => {
        return res;
      });
    },
    async delshopproductdifference(data, callback) {
      return await api.post('/product/delshopdifference', data, callback).then(({ res }) => {
        return res;
      });
    },
    async refreshshopproductcache(data, callback) {
      return await api.post('/product/refreshshopcache', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editrelatedprice(data, callback) {
      return await api.post('/product/editrelatedprice', data, callback).then(({res}) => {
        return res;
      });
    },

    //===========模具===========
    async getmould(data, callback) {
      return await api.post('/mould/all/' + data.page + "/" + data.row, data, callback, ['page', 'list', 'row']).then(({ res }) => {
        return res;
      });
    },
    async getmouldbyoffer(data, callback) {
      return await api.get('/mould/offer/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getmouldbyorderid(data, callback) {
      return await api.get('/mould/byorderid/' + data, '', callback).then((d) => {
        return d;
      });
    },
    async addmould(data, callback) {
      return await api.post('/mould', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editmould(data, callback) {
      return await api.put('/mould', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getmoulddetail(data, callback) {
      return await api.get('/mould/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delmould(data, callback) {
      return await api.post('/mould/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatemouldstate(data, callback) {
      return await api.put('/mould/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    //模具属性
    async getmouldattr(data, callback) {
      return await api.get("/mouldattr", data, callback).then((d) => {
        return d;
      });
    },
    async getmouldattrdetail(data, callback) {
      return await api.get("/mouldattr/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addmouldattr(data, callback) {
      return await api.post("/mouldattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editmouldattr(data, callback) {
      return await api.put("/mouldattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delmouldattr(data, callback) {
      return await api.post("/mouldattr/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatemouldattrstate(data, callback) {
      return await api.put("/mouldattr/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getmouldattrvalue(data, callback) {
      return await api.get("/mouldattrvalue/" + data, "", callback).then((d) => {
        return d;
      });
    },
    //产品内容
    async getproductcontent(data, callback) {
      return await api.get("/productcontent", data, callback).then((d) => {
        return d;
      });
    },
    async getproductcontentdetail(data, callback) {
      return await api.get("/productcontent/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addproductcontent(data, callback) {
      return await api.post("/productcontent", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editproductcontent(data, callback) {
      return await api.put("/productcontent", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delproductcontent(data, callback) {
      return await api.post("/productcontent/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateproductcontentstate(data, callback) {
      return await api.put("/productcontent/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品栏目
    async getproductcolumn(data, callback) {
      return await api.get("/productcolumn", data, callback).then((d) => {
        return d;
      });
    },
    async getproductcolumndetail(data, callback) {
      return await api.get("/productcolumn/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addproductcolumn(data, callback) {
      return await api.post("/productcolumn", data, callback).then(({res}) => {
        return res;
      });
    },
    async editproductcolumn(data, callback) {
      return await api.put("/productcolumn", data, callback).then(({res}) => {
        return res;
      });
    },
    async delproductcolumn(data, callback) {
      return await api.post("/productcolumn/delete", data, callback).then((res) => {
        return res;
      });
    },
    async updateproductcolumnstate(data, callback) {
      return await api.put("/productcolumn/updatestate", data, callback).then((res) => {
        return res;
      });
    },
    //产品套餐
    async getproductcombo(data, callback) {
      return await api.get("/productcombo", data, callback).then(({ d }) => {
        this.productcombolist = d;
        return d;
      });
    },
    async getproductcombodetail(data, callback) {
      return await api.get("/productcombo/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addproductcombo(data, callback) {
      return await api.post("/productcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editproductcombo(data, callback) {
      return await api.put("/productcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delproductcombo(data, callback) {
      return await api.post("/productcombo/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateproductcombostate(data, callback) {
      return await api.put("/productcombo/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品详情模板
    async getproductdetailsort(data, callback) {
      return await api.get("/productdetailsort/" + data.page + "/" + data.row, data, callback,["page","row"]).then((d) => {
        return d;
      });
    },
    async getproductdetailsortdetail(data, callback) {
      return await api.get("/productdetailsort/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addproductdetailsort(data, callback) {
      return await api.post("/productdetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editproductdetailsort(data, callback) {
      return await api.put("/productdetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delproductdetailsort(data, callback) {
      return await api.post("/productdetailsort/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateproductdetailsortstate(data, callback) {
      return await api.put("/productdetailsort/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },


    //新品详情模板
    async getnewproductdetail(callback) {
      return await api.get("/newproductdetail", "", callback).then((d) => {
        return d;
      });
    },
    async getnewproductdetaildetail(data, callback) {
      return await api.get("/newproductdetail/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addnewproductdetail(data, callback) {
      return await api.post("/newproductdetail", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editnewproductdetail(data, callback) {
      return await api.put("/newproductdetail", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delnewproductdetail(data, callback) {
      return await api.post("/newproductdetail/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatenewproductdetailstate(data, callback) {
      return await api.put("/newproductdetail/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },


    //模具栏目
    async getmouldcolumnall(callback) {
      return await api.get("/mouldcolumn/all", "", callback).then((d) => {
        return d;
      });
    },
    async getmouldcolumn(data, callback) {
      return await api.get("/mouldcolumn/" + data.id, data, callback).then((d) => {
        return d;
      });
    },
    async getmouldcolumndetail(data, callback) {
      return await api.get("/mouldcolumn/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addmouldcolumn(data, callback) {
      return await api.post("/mouldcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editmouldcolumn(data, callback) {
      return await api.put("/mouldcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delmouldcolumn(data, callback) {
      return await api.post("/mouldcolumn/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatemouldcolumnstate(data, callback) {
      return await api.put("/mouldcolumn/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品套餐
    async getmouldcombo(data, callback) {
      return await api.get("/mouldcombo", data, callback).then((d) => {
        return d;
      });
    },
    async getmouldcombodetail(data, callback) {
      return await api.get("/mouldcombo/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addmouldcombo(data, callback) {
      return await api.post("/mouldcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editmouldcombo(data, callback) {
      return await api.put("/mouldcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delmouldcombo(data, callback) {
      return await api.post("/mouldcombo/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatemouldcombostate(data, callback) {
      return await api.put("/mouldcombo/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品详情模板
    async getmoulddetailsort(data, callback) {
      return await api.get("/moulddetailsort", data, callback).then((d) => {
        return d;
      });
    },
    async getmoulddetailsortdetail(data, callback) {
      return await api.get("/moulddetailsort/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addmoulddetailsort(data, callback) {
      return await api.post("/moulddetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editmoulddetailsort(data, callback) {
      return await api.put("/moulddetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delmoulddetailsort(data, callback) {
      return await api.post("/moulddetailsort/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatemoulddetailsortstate(data, callback) {
      return await api.put("/moulddetailsort/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //设计产品栏目
    async getdesignproductcolumnall(data, callback) {
      return await api.get("/designproductcolumn/all", data, callback).then((d) => {
        return d;
      });
    },
    async getdesignproductcolumn(data, callback) {
      return await api.get("/designproductcolumn", data, callback).then((d) => {
        return d;
      });
    },
    async getdesignproductcolumndetail(data, callback) {
      return await api.get("/designproductcolumn/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async adddesignproductcolumn(data, callback) {
      return await api.post("/designproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editdesignproductcolumn(data, callback) {
      return await api.put("/designproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async deldesignproductcolumn(data, callback) {
      return await api.post("/designproductcolumn/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatedesignproductcolumnstate(data, callback) {
      return await api.put("/designproductcolumn/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //工程品栏目
    async getconstructionproductcolumnall(data, callback) {
      return await api.get("/constructionproductcolumn/all", data, callback).then((d) => {
        return d;
      });
    },
    async getconstructionproductcolumn(data, callback) {
      return await api.get("/constructionproductcolumn", data, callback).then((d) => {
        return d;
      });
    },
    async getconstructionproductcolumndetail(data, callback) {
      return await api.get("/constructionproductcolumn/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addconstructionproductcolumn(data, callback) {
      return await api.post("/constructionproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editconstructionproductcolumn(data, callback) {
      return await api.put("/constructionproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delconstructionproductcolumn(data, callback) {
      return await api.post("/constructionproductcolumn/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateconstructionproductcolumnstate(data, callback) {
      return await api.put("/constructionproductcolumn/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },

    //设计产品属性
    async getdesignprodattr(data, callback) {
      return await api.get("/designproductattr", data, callback).then((d) => {
        return d;
      });
    },
    async getdesignprodattrdetail(data, callback) {
      return await api.get("/designproductattr/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async adddesignprodattr(data, callback) {
      return await api.post("/designproductattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editdesignprodattr(data, callback) {
      return await api.put("/designproductattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async deldesignprodattr(data, callback) {
      return await api.post("/designproductattr/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatedesignprodattrstate(data, callback) {
      return await api.put("/designproductattr/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getdesignprodattrvalue(data, callback) {
      return await api.get("/designproductattrvalue/" + data, "", callback).then((d) => {
        return d;
      });
    },



    //工程产品属性
    async getconstructionprodattr(data, callback) {
      return await api.get("/constructionproductattr", data, callback).then((d) => {
        return d;
      });
    },
    async getconstructionprodattrdetail(data, callback) {
      return await api.get("/constructionproductattr/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addconstructionprodattr(data, callback) {
      return await api.post("/constructionproductattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editconstructionprodattr(data, callback) {
      return await api.put("/constructionproductattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delconstructionprodattr(data, callback) {
      return await api.post("/constructionproductattr/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateconstructionprodattrstate(data, callback) {
      return await api.put("/constructionproductattr/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getconstructionprodattrvalue(data, callback) {
      return await api.get("/constructionproductattrvalue/" + data, "", callback).then((d) => {
        return d;
      });
    },
    //周边产品
    //产品栏目
    async getperipheralproductcolumn(data, callback) {
      return await api.get("/peripheralproductcolumn", data, callback).then((d) => {
        return d;
      });
    },
    async getperipheralproductcolumndetail(data, callback) {
      return await api.get("/peripheralproductcolumn/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addperipheralproductcolumn(data, callback) {
      return await api.post("/peripheralproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editperipheralproductcolumn(data, callback) {
      return await api.put("/peripheralproductcolumn", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delperipheralproductcolumn(data, callback) {
      return await api.post("/peripheralproductcolumn/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateperipheralproductcolumnstate(data, callback) {
      return await api.put("/peripheralproductcolumn/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品套餐
    async getperipheralproductcombo(data, callback) {
      return await api.get("/peripheralproductcombo", data, callback).then((d) => {
        return d;
      });
    },
    async getperipheralproductcombodetail(data, callback) {
      return await api.get("/peripheralproductcombo/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addperipheralproductcombo(data, callback) {
      return await api.post("/peripheralproductcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editperipheralproductcombo(data, callback) {
      return await api.put("/peripheralproductcombo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delperipheralproductcombo(data, callback) {
      return await api.post("/peripheralproductcombo/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateperipheralproductcombostate(data, callback) {
      return await api.put("/peripheralproductcombo/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品详情模板
    async getperipheralproductdetailsort(data, callback) {
      return await api.get("/peripheralproductdetailsort", data, callback).then((d) => {
        return d;
      });
    },
    async getperipheralproductdetailsortdetail(data, callback) {
      return await api.get("/peripheralproductdetailsort/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addperipheralproductdetailsort(data, callback) {
      return await api.post("/peripheralproductdetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editperipheralproductdetailsort(data, callback) {
      return await api.put("/peripheralproductdetailsort", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delperipheralproductdetailsort(data, callback) {
      return await api.post("/peripheralproductdetailsort/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateperipheralproductdetailsortstate(data, callback) {
      return await api.put("/peripheralproductdetailsort/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品促销
    async getproductpromotion(data, callback) {
      return await api.get("/productpromotion", data, callback).then(({ d }) => {
        this.productpromotionlist = d;
        return d;
      });
    },
    async getproductpromotiondetail(data, callback) {
      return await api.get("/productpromotion/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addproductpromotion(data, callback) {
      return await api.post("/productpromotion", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editproductpromotion(data, callback) {
      return await api.put("/productpromotion", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delproductpromotion(data, callback) {
      return await api.post("/productpromotion/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateproductpromotionstate(data, callback) {
      return await api.put("/productpromotion/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    //产品属性
    async getprodattr(data, callback) {
      return await api.get("/productattr/" + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async getprodattrdetail(data, callback) {
      return await api.get("/productattr/detail/" + data.i, data, callback).then((d) => {
        return d;
      });
    },
    async addprodattr(data, callback) {
      return await api.post("/productattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editprodattr(data, callback) {
      return await api.put("/productattr", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delprodattr(data, callback) {
      return await api.post("/productattr/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateprodattrstate(data, callback) {
      return await api.put("/productattr/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getprodattrvalue(data, callback) {
      return await api.get("/productattrvalue/" + data, "", callback).then((d) => {
        return d;
      });
    },
    async findproductbycode(data, callback) {
      return await api.get('/product/findbycode', data, callback, ["list"]).then((d) => {
        return d;
      });
    },
    async getproductbydesign(data, callback) {
      return await api.get('/product/bydesign/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async editproductviewandsalenum(data, callback) {
      let url = '/product/editviewandsalenum';
      return await api.post(url, data, callback).then(({ res }) => {
        return res;
      });
    },
    async synchshopproductrelated(data, callback) {
        return await api.get('/product/synchshoprelated', data, callback).then((d) => {
            return d;
        });
    },
    async findundefinedwater(data, callback) {
        return await api.get('/v2/fileview/findunfinedwater', "", callback).then((d) => {
            return d;
        });
    }
  }
})