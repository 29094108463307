
<template>
    <Modal v-model="value" width="830" :styles="{ 'top': '10px' }" :mask-closable="false" :closable="false">
        <template #header>
            <h3>高级搜索</h3>
        </template>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="90" inline>
                <FormItem label="开始时间">
                    <DatePicker type="date" v-model="formItem.addTime1" placeholder="请选择开始时间" style="width: 170px">
                    </DatePicker>
                </FormItem>
                <FormItem label="结束时间">
                    <DatePicker type="date" v-model="formItem.addTime2" placeholder="请选择结束时间" style="width: 170px">
                    </DatePicker>
                </FormItem>
                <FormItem label="售后人员">
                    <Input v-model="formItem.userName" readonly style="width:170px">
                    <template #append>
                        <Button icon="ios-search"
                            @click="operation.action = 'AFTERSALES', setwebmodel({ state: true, path: '/userlist', query: { companyId: user.companyId, filtercode: 'AFTERSALES' } })">
                        </Button>
                    </template>
                    </Input>

                </FormItem>
                <FormItem label="图纸/项目编号">
                    <Input v-model="formItem.name" style="width:170px"></Input>
                </FormItem>
                <FormItem label="联系方式">
                    <Input v-model="formItem.contact" style="width:170px"></Input>
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button icon="md-close" @click="$emit('update:modelValue', false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </template>
    </Modal>
</template>
<script>
import { useUserStore, useCommsStore } from "@/store/user/user";
import { storeToRefs } from "pinia";

export default {
    name: "AfterProblemAdvanceFilter",
    setup() {
        const userstore = useUserStore();
        const commsstore = useCommsStore();
        const { user } = storeToRefs(userstore);
        const { webmodel, selected } = storeToRefs(commsstore);
        return {
            user,
            selected,
            webmodel,
            setwebmodel: commsstore.setwebmodel
        }
    },

    data() {
        return {
            provincelist: [],
            citylist: [],
            buildlist: [],
            organizelist: [],
            formItem: {
                addTime1: "",
                addTime2: "",
                name: "",
                contact: "",
                userId: "",
                userName: ""
            },
            operation: {
                action: "",
                id: ""
            }
        }
    },
    props: {
        state: Boolean,
        clearData: Function
    },
    computed: {
        value: {
            get() {
                return this.state;
            },
            set(value) {
                this.$emit("update:state", value);
            }
        }

    },
    methods: {
        submitSearchHandle() {
            let _filter = [];
            for (let key in this.formItem) {
                if (this.formItem[key]) {
                    if (key == "name") {
                        _filter.push({
                            name: "Name",
                            condition: 0,
                            value: this.formItem[key],
                            label: "图纸/项目编号:"
                        })
                    }
                    else if (key == "contact" && this.formItem[key]) {
                        _filter.push({
                            name: "Contact",
                            condition: 0,
                            value: this.formItem[key],
                            label: "联系方式:"
                        })
                    }
                    else if (key == "userId" && this.formItem[key]) {
                        _filter.push({
                            name: "UserId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "售后人员:",
                            aliasvalue:this.formItem["userName"]
                        })
                    }
                    else if (key == "addTime1" && this.formItem[key]) {
                        _filter.push({
                            name: "AddTime",
                            condition: 6,
                            value: utils.formatTime(this.formItem[key]).split(" "),
                            label: "开始时间:"
                        })
                    }
                    else if (key == "addTime2" && this.formItem[key]) {
                        _filter.push({
                            name: "AddTime",
                            condition: 7,
                            value: utils.formatTime(this.formItem[key]).split(" "),
                            label: "结束时间:"
                        })
                    }
                }
            }
            this.$emit('update:modelValue', false)
            setTimeout(() => {
                this.$emit("on-change", _filter);
            }, 100);
        },
        clearAdvanceHandle() {
            for (let key in this.formItem) {
                if (typeof this.formItem[key] == "string") {
                    this.formItem[key] = "";
                }
                else if (typeof this.formItem[key] == "number") {
                    this.formItem[key] = 0;
                }
            }
        }
    },
    watch: {
        "selected": function () {
            let _key = this.selected.key;
            let _datas = this.selected.datas;
            if (utils.webmodelCanUpdate(this)) {
                // if (!this.comms.webmodel.state && this.comms.webmodel.key == "refresh") {
                //     this.$set(this.ttcallback, "refresh", true);
                // }
                // else if (!this.comms.webmodel.state && this.comms.webmodel.key == "userlist" && this.comms.webmodel.action == "result") {
                if (_key == "userlist") {
                    this.formItem.userId = _datas.id;
                    this.formItem.userName = _datas.userName;
                }
                // }
            }
        },
        "clearData": function () {
            this.clearAdvanceHandle();
        }
    }
}
</script>