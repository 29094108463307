<template>
    <div>
        <Select v-model="provinceId" v-if="level >= 1" :style="{ width: long ? '100%' : (small ? '90px' : '133px') }"
            filterable @on-change="selectProvince" label-in-value :disabled="view" not-found-text="删除当前文字继续选择">
            <Option v-for="item in theprovince" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="cityId" v-if="level >= 2" :style="{ width: long ? '100%' : (small ? '100px' : '123px') }" filterable
            @on-change="selectCity" label-in-value :disabled="view" not-found-text="删除当前文字继续选择">
            <Option v-for="item in thecity" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="areaId" v-if="level >= 3" :style="{ width: long ? '100%' : (small ? '100px' : '123px') }" filterable
            @on-change="selectArea" label-in-value :disabled="view" not-found-text="删除当前文字继续选择">
            <Option v-for="item in thearea" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
    </div>
</template>

<script>
import { useBaseInfoStore } from "@/store/crm/baseinfo";

export default {
    name: 'FormArea',
    setup() {
        const baseinfostore = useBaseInfoStore();
        return {
            baseinfostore
        }
    },

    props: {
        view: {
            default: false
        },
        init: {
            type: Object,
            default: function () {
                return {
                    province: "",
                    provinceId: 0,
                    city: "",
                    cityId: 0,
                    area: "",
                    areaId: 0
                }
            }
        },
        long: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        level: {
            type: Number,
            default: 3
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            theprovince: [],
            thecity: [],
            thearea: [],
            provinceId: 0,
            cityId: 0,
            areaId: 0,
            areas: {
                province: "",
                provinceId: "",
                city: "",
                cityId: "",
                area: "",
                areaId: ""
            },
            operation: {
                action: ""
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            var _init = JSON.parse(JSON.stringify(this.init));
            var _provincetemp = parseInt(_init.provinceId || 0);
            var _citytemp = parseInt(_init.cityId || 0);
            var _areatemp = parseInt(_init.areaId || 0);
            this.baseinfostore.getAreaList({ provinceId: _provincetemp, cityId: _citytemp }, false).then(d => {
                this.theprovince = d.province;
                this.thecity = d.city;
                this.thearea = d.area;
                if (_provincetemp > 0) {
                    this.provinceId = _provincetemp;
                }
                if (_citytemp > 0) {
                    this.cityId = _citytemp;
                }
                if (_areatemp > 0) {
                    this.areaId = _areatemp;
                }
            });
        },
        selectProvince(val) {
            if (!val) { return false; }
            if (val.label) {
                this.baseinfostore.getAreaList({ provinceId: this.provinceId, cityId: this.cityId }, false).then(d => {
                    this.theprovince = d.province;
                    this.thecity = d.city;
                    this.thearea = d.area;
                });
                this.areas.provinceId = val.value;
                this.areas.province = val.label;
                this.cityId = 0;
                this.areaId = 0;
            }

        },
        selectCity(val) {
            if (!val) { return false; }
            if (val.label) {
                this.areas.cityId = val.value;
                this.areas.city = val.label;
                this.areaId = 0;
                this.baseinfostore.getAreaList({ provinceId: this.provinceId, cityId: this.cityId }, false).then(d => {
                    this.theprovince = d.province;
                    this.thecity = d.city;
                    this.thearea = d.area;
                });
                if (this.level == 2) {
                    setTimeout(() => {
                        this.$emit("update:modelValue", this.areas);
                        this.$emit("on-change", this.areas, this.index)
                    }, 100);
                }
            }
        },
        selectArea(val) {
            if (!val) { return false; }
            if (val.label) {
                this.areas.areaId = val.value;
                this.areas.area = val.label;

                //如果provinceId,areaId是空,要去init中找
                if(!this.areas.provinceId){
                    this.areas.provinceId = this.init.provinceId;
                    this.areas.province = this.init.province;
                }
                if(!this.areas.cityId){
                    this.areas.cityId = this.init.cityId;
                    this.areas.city = this.init.city;
                }
                
            }
            if (this.level == 3) {
                setTimeout(() => {
                    this.$emit("update:modelValue", this.areas);
                    this.$emit("on-change", this.areas, this.index)
                }, 100);
            }
        }
    },
    watch: {
        "init": {
            deep: true,
            handler: function () {
                this.areas.provinceId = this.init.provinceId;
                this.areas.province = this.init.province;
                this.areas.cityId = this.init.cityId;
                this.areas.city = this.init.cityId;
                this.areas.areaId = this.init.areaId;
                this.areas.area = this.init.area;
                this.loadData();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>