<style lang="scss" scoped>
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
<template>
  <div
    style="line-height: 0px"
    @click="
      () => {
        if (datarequire) {
          this.$Message.error(requireerror);
        }
      }
    "
  >
    <div
      class="demo-upload-list"
      v-for="(item, i) in uploadList"
      :key="'consultedit' + i"
    >
      <template v-if="item.status === 'finished'">
        <img
          v-if="water > -1"
          :src="
            item.path == 'full'
              ? item.url
              : ver == 'v2'
              ? v2viewurl(item.url)
              : configs.viewpath + '/' + item.url
          "
          style="width: 100%"
        />
        <img
          v-else
          :src="
            item.path == 'full'
              ? item.url
              : ver == 'v2'
              ? v2viewurl(item.url)
              : configs.downloadpath + '/' + item.url
          "
          style="width: 100%"
        />
        <!-- height: 150px -->
        <div class="demo-upload-list-cover">
          <Icon
            type="ios-eye-outline"
            @click.native="handleView(item.path, item.url)"
          ></Icon>
          <Icon
            type="ios-trash-outline"
            v-if="!view"
            @click="handleRemove(item.url, i)"
          ></Icon>
        </div>
      </template>
      <template v-else>
        <Progress
          v-if="item.showProgress"
          :percent="item.percentage"
          hide-info
        ></Progress>
      </template>
    </div>
    <Upload
      ref="upload"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :on-success="handleSuccess"
      :max-size="20480"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :before-upload="handleBeforeUpload"
      :accept="accept"
      :with-credentials="true"
      multiple
      type="drag"
      :action="
        action
          ? action
          : (ver == 'v2' ? configs.upload2 : configs.upload) +
            (path
              ? 'dir=' +
                path +
                (ver == 'v2'
                  ? (bizId ? '&bizId=' + bizId : '') +
                    '&water=' +
                    water +
                    '&index=' +
                    (water=='-1'?'-1':index)+
                    (bindId ? '&id=' + bindId : '') +
                    ConcatParams
                  : '')
              : '')
      "
      style="display: inline-block; width: 58px"
      :disabled="datarequire"
      v-if="CanUpload"
    >
      <!-- 同步上传(async=false)的时候，要调整handleBeforeUpload -->
      <div style="width: 58px; height: 58px; line-height: 58px">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
    <Modal
      title="查看图片"
      footer-hide
      v-model="visible"
      :styles="{ top: '10px' }"
      :closable="false"
      cancel-text=""
      :width="viewwith > 0 ? viewwith : 800"
    >
      <template #header>
        <div class="modalheaderclose">
          查看图片
          <Icon type="md-close-circle" size="25" @click="visible = false" />
        </div>
      </template>
      <img :src="imgName" v-if="visible" style="width: 100%" />
    </Modal>
    <div v-if="view && uploadList.length == 0" style="margin-top: 16px">无</div>
  </div>
</template>

<script>
import { useUserStore } from "@/store/user/user";
import { reactive, toRefs } from "vue";
export default {
  name: "FormUpload",
  props: {
    action: {
      type: String,
      default: "",
    },
    singlefile: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    view: {
      type: Boolean,
      default: false,
    },
    default: {
      type: Array,
      default: function () {
        return [];
      },
    },
    index: {
      type: Number,
      default: -1,
    },
    maxfilenum: {
      type: Number,
      default: 20,
    },
    accept: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    viewwith: {
      type: Number,
      default: 0,
    },
    ver: {
      type: String,
      default: "",
    },
    async: {
      type: Boolean,
      default: true,
    },
    ondel: {
      type: String,
      default: "",
    },
    bizId: {
      type: String,
      default: "",
    },
    bindId: {
      type: String,
      default: "",
    },
    water: {
      type: Number,
      default: -1,
    },
    datarequire: {
      type: Boolean,
      default: false,
    },
    requireerror: String,
    params: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const userstore = useUserStore();
    const state = reactive({
      configs: userstore.configs,
    });
    return {
      ...toRefs(state),
      userstore,
    };
  },
  data() {
    return {
      defaultList: [],
      uploadList: [],
      visible: false,
      iLike: [],
      format: ["jpg", "jpeg", "png"],
      formData: new FormData(),
      formdatatemp: [],
      uploadlock: true,
      parentnode: this,
    };
  },
  computed: {
    ConcatParams() {
      let _params = "";
      for (let key in this.params) {
        // if(_params){
        //   _params+=`&`;
        // }
        _params += `&${key}=${this.params[key]}`;
      }
      return _params;
    },
    CanUpload() {
      if (this.view) {
        return false;
      } else {
        if (this.singlefile && this.uploadList.length >= 1) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (this.default.length > 0) {
      this.default.forEach((e) => {
        this.uploadList.push({
          url: e.url,
          status: "finished",
          path: e.path,
        });
        this.iLike.push(e.url);
      });
    }
  },
  methods: {
    handleSuccess(file) {
      if (file.error == 0) {
        if (this.ver == "v2") {
          file.datas.forEach((e) => {
            var _url = "";
            if (this.path.indexOf("/local/") > -1) {
              _url =
                "/" +
                e.basePath +
                "/" +
                e.id +
                (this.index > 0 ? "_" + e.index : "") +
                e.extend +
                "?random=" +
                Math.random();
            } else {
              _url = e.id;
            }
            this.uploadList.push({ url: _url, status: "finished" });
          });
        } else {
          this.uploadList.push({ url: file.id[0], status: "finished" });
        }
        this.iLike = [];
        this.uploadList.forEach((e) => {
          this.iLike.push(e.url);
        });
        setTimeout(()=>{
          this.$emit("change", this.iLike,this.index);
          this.$emit("loadingstate", false);
        },0);
        this.uploadlock = true;
        this.formdatatemp = [];
        this.formData = new FormData();
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式不正确",
        desc: "文件 " + file.name + " 格式不正确，请上传 jpg 或 png 格式的图片。",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "超出文件大小限制",
        desc: "文件 " + file.name + " 太大不能超过 20M。",
      });
    },
    handleBeforeUpload(file) {
      if (!this.async) {
        //同步上传
        this.formdatatemp.push(file);
        if (this.uploadlock) {
          this.uploadlock = false;
          setTimeout(() => {
            this.$emit("loadingstate", true);
            if (this.singlefile) {
              const check = this.uploadList.length < 1 || this.formdatatemp.length > 1;
              if (!check) {
                this.$Notice.warning({
                  title: "最多只能上传 1 张图片。",
                });
                this.$emit("loadingstate", false);
                return false;
              }
            } else {
              if (
                this.uploadList.length >= this.maxfilenum ||
                this.formdatatemp.length > this.maxfilenum
              ) {
                this.$Notice.warning({
                  title: "最多只能上传 " + this.maxfilenum + " 张图片。",
                });
                this.$emit("loadingstate", false);
                return false;
              }
            }

            this.formdatatemp.forEach((e) => {
              this.formData.append("files[]", e);
            });

            this.userstore
              .fileupload2({
                data: this.formData,
                params: this.path
                  ? "?dir=" +
                    this.path +
                    (this.ver == "v2"
                      ? (this.bizId ? "&bizId=" + this.bizId : "") +
                        "&water=" +
                        this.water +
                        "&index=" +
                        (this.water=="-1"?"-1":this.index) +
                        (this.bindId ? "&id=" + this.bindId : "") +
                        this.ConcatParams
                      : "")
                  : "",
              })
              .then((d) => {
                this.handleSuccess(d);
              });
          }, 50);
        }
        return false;
      } else {
        this.$emit("loadingstate", true);
        if (this.singlefile) {
          const check = this.uploadList.length < 1;
          if (!check) {
            this.$Notice.warning({
              title: "最多只能上传 1 张图片。",
            });
            this.$emit("loadingstate", false);
          }
          return check;
        } else {
          if (this.uploadList.length >= this.maxfilenum) {
            this.$Notice.warning({
              title: "最多只能上传 " + this.maxfilenum + " 张图片。",
            });
            this.$emit("loadingstate", false);
            return false;
          }
          return true;
        }
      }
    },
    handleView(path, url) {
      this.imgName =
        path == "full"
          ? url
          : this.ver == "v2"
          ? this.v2viewurl(url)
          : this.configs.downloadpath + "/" + url;
      this.visible = true;
    },
    handleRemove(url, index) {
      if (
        this.ondel &&
        this.$parent.$parent.$parent[this.ondel] &&
        this.uploadList.length > 1 &&
        index < this.uploadList.length - 1
      ) {
        this.$parent.$parent.$parent[this.ondel]();
      } else {
        this.iLike = [];
        // this.uploadList = [];
        this.uploadList.splice(index,1);
          

        // this.uploadList.forEach((v, i) => {
        //   if (v.url == url) {
        //     this.uploadList.splice(i, 1);
        //   }
        // });
        setTimeout(()=>{
          this.uploadList.forEach((e) => {
            this.iLike.push(e.url);
          });
          this.$emit("change", this.iLike,this.index);
        },0);
        // this.$emit("update:modelValue", this.iLike);
      }
    },
    v2viewurl(url) {
      if (this.path.indexOf("/oss/") > -1 || this.path.indexOf("/nas/") > -1) {
        return this.configs.viewpath2 + "/" + (url ? url.replace(/\//g, "@") : ""); //oss
      } else {
        if (url.indexOf("http:") > -1 || url.indexOf("https:") > -1) {
          return url;
        } else {
          return this.configs.basepath + url;
        }
      }
    },
  },
  watch: {
    default: {
      deep: true,
      handler: function () {
        this.uploadList = [];
        this.iLike = [];
        if (this.default && this.default.length > 0) {
          this.default.forEach((e) => {
            this.uploadList.push({
              url: e.url,
              status: "finished",
              path: e.path,
            });
            this.iLike.push(e.url);
          });
        }
        // this.$emit("update:modelValue", this.iLike);
      },
    },
    path: function () {
      if (this.path.indexOf("/oss/") > -1) {
        this.viewpath1 = "/oss/";
      } else if (this.path.indexOf("nas") > -1) {
        this.viewpath1 = "/nas/";
      }
    },
  },
};
</script>
