
<style>
.ueditor .ivu-form-item-content {
  line-height: 0px !important;
}
</style>
<template>
  <div>
    <div v-if="!view" :style="{ 'width': (width || '500px') }">
      <VueUeditorWrap v-model="value" :config="config" @ready="editReady" editor-id="editor-demo-01"></VueUeditorWrap>
      <!-- <vue-ueditor-wrap v-model="msg" :config="config" @ready="ready"></vue-ueditor-wrap> -->
    </div>
    <div v-else-if="!value">无</div>
    <div v-else v-html="value"></div>
  </div>
</template>
<script>
// import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'FormEditor',
  props: {
    view: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    width: Number,
    bizId: String,
    fromType: String,
    code: String,
    requiredCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editorobj: "",
      msg: "",
      config: {
        autoHeightEnabled: false,
        initialFrameWidth: "100%",
        initialFrameHeight: 350,
        autoFloatEnabled: false,
        UEDITOR_HOME_URL: '/static/UEditor/',
        // serverUrl: '//ueditor.zhenghaochuan.com/cos'
      },
      editorInstance: ""
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  mounted() {
  },
  methods: {
    editReady(editorInstance) {
      this.editorInstance = editorInstance;
      this.init();
      // if (this.requiredCode && !this.code) {
      //   editorInstance.setDisabled();
      // }
      // else {
      //   editorInstance.setEnabled();
      // }
      // this.editorobj = editorInstance;
      // if (this.value) {
      //   setTimeout(() => {
      //     this.msg = this.value;
      //   }, 100);
      // }
    },
    init(){
      let _params = {};
      if (this.bizId) {
        _params.bizId = this.bizId;
      }
      if (this.fromType) {
        _params.fromType = this.fromType;
      }
      if (this.code) {
        _params.code = this.code;
      }
      if (this.requiredCode && this.code) {
        this.editorInstance.setEnabled();
      }
      else if(this.requiredCode && !this.code){
        this.editorInstance.setDisabled();
      }
      else{
        this.editorInstance.setEnabled();
      }
      this.editorInstance.execCommand('serverparam', _params);
    }
  },
  watch: {
    "code": function () {
      this.init();
    }
  }
}
</script>

